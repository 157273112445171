.image-preview {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100vh;
  animation: doVisible;
  animation-duration: 300ms;

  &__background {
    width: 100%;
    height: 100%;
    background: rgba($blue-grey, 0.5);
    position: absolute;
  }

  &__wrapper {
    @include container;
    min-height: 162px;
    display: flex;
    position: relative;
    box-shadow: 0px 4px 32px rgba(42, 53, 110, 0.12);
  }

  &__image {
    width: 100%;
    object-fit: contain;
  }

  &__close-button {
    position: absolute;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $grey-15;
    top: 8px;
    right: 24px;
    padding: 10px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    transition-duration: 300ms;

    & path {
      transition-duration: 300ms;
    }

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
    }

    &:active rect {
      fill: $white;
    }
  }
}

@media ($tablet) {
  .image-preview {
    &__wrapper {
      min-height: 396px;
    }

    &__close-button {
      top: 10px;
      right: 10px;
    }
  }
}

@media ($desktop) {
  .image-preview {
    &__wrapper {
      width: 800px;
      min-height: auto;
      height: 450px;
    }
  }
}

@media ($desktop-hd) {
  .image-preview {
    &__wrapper {
      width: 1240px;
      height: 697px;
    }
  }
}

@keyframes doVisible {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}
