.suggest-button {
  @include bold;
  font-size: 14px;
  line-height: 17px;
  color: $white;
  background: $primary-50;
  border: none;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background-color: $primary-40;
  }

  &:active {
    background-color: $primary-60;
  }

  &--disabled {
    background: $primary-50;
    cursor: default;

    &:hover {
      background-color: $blue-grey-60;
    }

    &:hover + .suggest-button__disabled-description {
      display: flex;
    }
  }

  &--no-email {
    background: #f3eafd;
    cursor: default;
    color:  $blue-grey-60;
    border: none;
    padding: 16px;

    &:hover {
      background: #ebd9ff;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__disabled-description {
    position: absolute;
    margin: 0;
    top: 55px;
    left: 0;
    width: 260px;
    display: none;
    flex-wrap: wrap;
    box-shadow: 0px 4px 12px rgba(7, 7, 73, 0.08);
    border-radius: 16px;
    background-color: $white;
    padding: 15px 20px;
    z-index: 3;
  }
}
