.sign-up-modal {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;

  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $blue-grey-70;
    opacity: 0.5;
  }

  &__modal {
    width: calc(100% - 32px);
    background-color: $grey;
    border-radius: 16px;
    position: fixed;
    top: 50%;
    margin-top: -225px;
    left: 16px;
    padding: 32px 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &__title {
    @include bold;
    font-size: 32px;
    line-height: 38px;
    margin: 0;
  }

  &__close-button {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: $grey-15;
    border: none;
    cursor: pointer;
    transition-duration: 300ms;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      background-color: $grey-20;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 24px;
  }

  &__error {
    padding: 12px 12px 12px 54px;
    border: 2px solid $red-40;
    border-radius: 12px;
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 12px;
      left: 12px;
      width: 24px;
      height: 24px;
      background-image: url('../../images/warning-icon.svg');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center;
    }
  }

  &__error-description {
    color: $red-40;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }

  &__email-link {
    color: $blue-grey-70;
  }

  &__sign-up-with-twitch-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-50;
    text-decoration: none;
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
    padding: 14px 40px;
    color: $white;
    @include bold;
    margin-bottom: 12px;
    transition-duration: 300ms;

    & svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: $primary-40;
    }

    &:active {
      background-color: $primary-60;
    }
  }

  &__dont-have-twitch-btn {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 15px;
    font-size: 14px;
    background-color: $grey-15;
    color: $blue-grey;
    @include bold;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition-duration: 300ms;
    margin-bottom: 12px;

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
      color: $white;
    }
  }

  &__agreement {
    font-size: 12px;
    line-height: 17px;
    margin: 0;
    color: $blue-grey-70;
  }

  &__agreement-link {
    color: inherit;
  }
}

@media ($tablet) {
  .sign-up-modal {
    &__modal {
      width: 544px;
      left: 50%;
      margin-left: -272px;
      margin-top: -237px;
      padding: 50px;
      padding-top: 56px;
    }

    &__header {
      margin-bottom: 16px;
    }

    &__title {
      font-size: 40px;
    }

    &__description {
      margin-bottom: 32px;
    }
  }
}
