.multi-select {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
  }

  &__input:checked + .multi-select__label {
    color: $white;

    background-color: $blue-grey;
  }

  &__label {
    display: inline-block;
    margin: 2px;
    padding: 9px 12px;

    font-size: 12px;
    line-height: 14px;
    color: $blue-grey-70;

    background-color: $grey-15;
    border-radius: 8px;

    transition-duration: 300ms;

    user-select: none;

    @include medium;
    cursor: pointer;

    &:hover {
      background-color: $grey-20;
    }
  }
}
