.filters {
  &__header {
    margin: 0 0 16px;

    font-size: 16px;
    line-height: 19px;
    color: $blue-grey;

    @include bold;
  }

  &__stream-time {
    margin-bottom: 24px;
    padding-bottom: 24px;

    border-bottom: 1px solid $blue-grey-20;
  }

  &__days-of-week {
    margin-bottom: 16px;
  }

  &__time-wrapper {
    display: flex;
    align-items: center;
  }

  &__input-wrapper {
    position: relative;
  }

  &__time-input {
    width: 100px !important;
    background: none;
    border: 1px solid $blue-grey-40;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 2px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: rgba($blue-grey, 0);

    @media ($desktop) {
      width: 100% !important;
    }

    @media ($desktop-hd) {
      width: 100px !important;
    }

    &.filled {
      color: rgba($blue-grey, 1);
    }

    &:hover {
      margin-bottom: 0;
      border-width: 2px;
      border-color: $blue-grey-40 !important;
      color: rgba($blue-grey, 1);
    }

    &:focus {
      border-width: 2px;
      border-color: $primary-50 !important;
      color: rgba($blue-grey, 1);
    }
  }

  &__input-label {
    font-size: 14px;
    font-weight: 500;
    color: $blue-grey-60;
    background-color: $grey;
    padding: 0 4px;
    position: absolute;
    top: 16px;
    left: 16px;
    transition-duration: 300ms;
  }

  &__input-wrapper input.filled + label,
  &__input-wrapper input:focus + label,
  &__input-wrapper input:hover + label {
    top: -5px;
    font-size: 12px;
    font-weight: 400;
    color: $blue-grey-50;
  }

  &__time-separator {
    display: inline-block;
    width: 12px;
    height: 1px;
    background-color: $blue-grey-30;
    margin: 0 16px;
  }

  &__games {
    margin-bottom: 16px;
  }

  &__followers {
    margin-top: 24px;
  }

  &__average-views {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 24px;

    border-bottom: 1px solid $blue-grey-20;
  }

  &__checkbox-item {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__checkbox-label {
    @include medium;
    font-size: 14px;
    position: relative;
    color: $blue-grey-60;
    padding-left: 32px;
    transition-duration: 300ms;
    cursor: pointer;

    user-select: none;
    display: flex;

    p {
      margin: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;

      display: inline-block;
      width: 24px;
      height: 24px;
      margin-top: -12px;

      background: url('../../../images/checkbox.svg') no-repeat;
      background-position: center;
      background-size: contain;
      transition-duration: 300ms;
    }

    &:hover {
      color: $blue-grey-70;
    }

    &:disabled {
      color: $blue-grey-60;
      cursor: auto;
    }

    &:hover::before {
      background-image: url('../../../images/checkbox-hover.svg');
    }
  }

  &__checkbox:checked + .filters__checkbox-label::before {
    background-image: url('../../../images/checkbox-checked.svg');
  }

  &__checkbox:disabled + .filters__checkbox-label::before {
    color: $blue-grey-60;
    cursor: not-allowed;
  }

  &__active-streamers {
    margin-top: 32px;
    margin-bottom: 12px;
  }

  &__apply-button {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  &__reset-button {
    width: 100%;
  }
}

.time-input {
  &__control {
    width: 100px !important;
  }

  &__menu-list {
    height: 245px !important;
  }

  &__option {
    padding-left: 0 !important;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media ($desktop) {
  .time-input {
    &__control {
      width: 92px !important;
    }

    &__menu-list {
      height: 340px !important;
    }

    &__placeholder {
      font-size: 12px !important;
    }

    &__single-value {
      font-size: 12px !important;
    }
  }
}

@media ($desktop-hd) {
  .time-input {
    &__control {
      width: 100px !important;
    }

    &__placeholder {
      font-size: 14px !important;
    }
  }
}
