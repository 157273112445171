.cookie-banner {
  width: 100%;
  position: fixed;
  bottom: env(safe-area-inset-bottom);

  &__container {
    @include container;
    width: 100%;
  }

  &__modal {
    background-color: $blue-grey;
    border-radius: 16px;
    margin-bottom: 40px;
    padding: 24px;
    width: 100%;
  }

  &__title {
    @include bold;
    margin: 0;
    color: $white;
    position: relative;
    padding-left: 64px;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 16px;

    @media ($tablet) {
      margin-bottom: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -24px;
      width: 48px;
      height: 48px;
      background-image: url('../../images/cookie-icon.svg');
      background-repeat: no-repeat;
      background-size: 35px 35px;
      background-position: center;

      @media ($desktop) {
        top: 0;
        margin-top: 0;
      }
    }
  }

  &__description {
    color: $white;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  &__privacy-link {
    color: $blue-50;
    margin: 0;
    margin-bottom: 16px;
  }

  &__accept-button {
    width: 100%;
  }
}

@media ($desktop) {
  .cookie-banner {
    &__container {
      display: flex;
      justify-content: center;
    }

    &__modal {
      width: 1184px;
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 24px;
    }

    &__title {
      margin-bottom: 6px;
      font-size: 24px;
    }

    &__description {
      padding-left: 64px;
      font-size: 16px;
      line-height: 22px;
      width: 940px;
    }

    &__accept-button {
      width: auto;
      margin-left: 16px;
    }
  }
}
