.accept-request-modal {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;

  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($blue-grey-70, 0.5);
  }

  &__modal {
    width: calc(100% - 32px);
    background-color: $grey;
    border-radius: 16px;
    position: fixed;
    top: 50%;
    margin-top: -186px;
    left: 16px;
    padding: 32px 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  &__close-button {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: $grey-15;
    border: none;
    cursor: pointer;
    transition-duration: 300ms;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      background-color: $grey-20;
    }
  }

  &__title {
    font-size: 32px;
    margin: 0;
    color: $blue-grey;

    & span {
      color: $blue-grey-50;
    }
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 32px;
  }
  &__input-label {
    font-size: 14px;
    color: $blue-grey-60;
    font-weight: 500;
    background-color: $grey;
    padding: 0 4px;
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 2;
    margin-left: 12px;
    transition-duration: 300ms;
  }

  &__input {
    min-height: 120px;
    border-radius: 12px;
    width: 100%;
    outline: none;
    border: 1px solid $blue-grey-40;
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
    color: $blue-grey-50;
    background: none;
    resize: vertical;

    &:hover {
      border-width: 2px;
      border-color: $blue-grey-40 !important;
    }

    &:focus {
      border-width: 2px;
      border-color: $primary-50 !important;
    }
  }

  &__input-wrapper textarea.filled + label,
  &__input-wrapper textarea:focus + label,
  &__input-wrapper textarea:hover + label {
    top: -5px;
    font-size: 12px;
    font-weight: 400;
    color: $blue-grey-50;
  }

  &__accept-button {
    width: 100%;
    border: none;
  }
}

@media ($tablet) {
  .accept-request-modal {
    &__modal {
      width: 736px;
      left: 50%;
      margin-left: -368px;
      padding: 40px;
    }

    &__title {
      & br {
        display: none;
        font-size: 40px;
      }
    }
  }
}
