.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @include bold;
  color: $white;
  font-size: 14px;
  padding: 16px 20px;
  border-radius: 12px;
  border: none;
  background: $primary-50;
  cursor: pointer;
  transition-duration: 300ms;
  text-decoration: none;

  & svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: $primary-40;
  }

  &:active {
    background-color: $primary-60;
  }

  @media ($desktop) {
    font-size: 16px;
    padding: 22px 26px;
    background-position: 24px center;
  }
}
