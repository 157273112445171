.spinner-wrapper {
  display: flex;
  justify-content: center;
  margin: 10px;
  color: $primary-50;
}

.spinner-wrapper * {
  border-color: $primary-50 !important;
}

.spinner-wrapper__label {
  margin-left: 5px;
  font-size: 11px;
  color: $blue-grey-50;
  user-select: none;
}
