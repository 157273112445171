.twitch-user-link {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: $grey-15;
  transition-duration: 300ms;
  color: $blue-grey;
  text-decoration: none;
  @include bold;
  padding: 15px 20px;

  & svg {
    margin-right: 8px;
  }

  & svg,
  & path {
    transition-duration: 300ms;
  }

  &:hover {
    background-color: $grey-20;
  }

  &:active {
    background-color: $blue-grey;
    color: $white;
  }

  &:active path {
    fill: $white;
  }
}
