.not-found {
  height: 100vh;
  &__container {
    @include container;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__error-code {
    display: block;
    position: relative;
    @include bold;
    font-size: 108px;

    &::before {
      content: '';
      position: absolute;
      width: 80px;
      height: 80px;
      background-image: url('../../images/sad-icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      top: -100px;
      left: 50%;
      margin-left: -40px;
    }

    @media ($desktop) {
      font-size: 160px;

      &::before {
        top: -120px;
      }
    }
  }

  &__description {
    color: $blue-grey-70;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    margin-bottom: 32px;

    @media ($desktop) {
      margin-bottom: 40px;
    }
  }
}
