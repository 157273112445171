.main {
  padding-top: 88px;
  margin-bottom: 110px;

  &__wrapper {
    @include container;
  }

  &__title {
    margin: 0 0 8px;

    font-size: 32px;
    line-height: 38px;

    @include bold;
  }

  &__description {
    margin: 0 0 40px;

    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-60;
  }

  &__block-title {
    font-size: 24px;
    line-height: 120%;
    margin-top: 70px;
    margin-bottom: 8px;
  }

  &__block-description {
    font-size: 14px;
    line-height: 140%;
    color: $blue-grey-70;
    margin: 0 0 32px;
  }

  &__status-wrapper {
    position: absolute;
    right: 4px;
    top: 15%;
    display: flex;
    width: 12px;
    height: 12px;
  }

  &__status {
    display: inline-block;
    width: 100% !important;
    height: 100% !important;
    background-color: $blue-grey-50;
    border: 2px solid $white;
    border-radius: 50%;

    &--green {
      background-color: $green-50;
    }

    &--red {
      background-color: $red-50;
    }
  }
}

@media ($desktop) {
  .main {
    padding-top: 30px;

    &__title {
      font-size: 38px;
      line-height: 55px;
    }

    &__description {
      margin-bottom: 32px;

      font-size: 16px;
      line-height: 22px;
    }

    &__block-title {
      font-size: 32px;
      margin-top: 0;
    }

    &__block-description {
      font-size: 16px;
    }

    &__container {
      display: flex;
      align-items: flex-start;
    }

    &__filters {
      min-width: 272px;
      max-width: 272px;
      margin-right: 32px;

      & .Collapsible__trigger {
        display: none;
      }

      & .Collapsible__contentOuter {
        margin-top: 0;
      }

      & .Collapsible__contentInner {
        border-radius: 12px;
        padding: 24px 26px;
      }
    }

    &__streamers-wrapper {
      flex-grow: 1;
    }
  }
}

@media ($desktop-hd) {
  .main {
    &__filters {
      min-width: 352px;
      max-width: 352px;
    }
  }
}
