.page-header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  background-color: $white;
  padding-top: 8px;
  padding-bottom: 8px;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include container;
  }

  &__logo-link {
    display: flex;
  }

  &__logo {
    display: inline-block;
    width: 90px;
    height: 26px;
    background-size: 90px 26px;
    background: url('../../images/logo.svg') no-repeat center;
  }

  &__userbar-wrapper {
    display: flex;
    align-items: center;
  }

  //&__blog-link {
  //  font-size: 12px;
  //  color: $blue-grey;
  //  @include bold;
  //  text-decoration: none;
  //
  //  @media ($desktop-hd) {
  //    font-size: 16px;
  //  }
  //}

  &__language-selector {

    .selector-with-icons {

      &__menu {
        max-width: max-content;
      }

      &__value-container--has-value {

        & .selector-with-icons__text {
          font-size: 0;

          @media ($tablet) {
            font-size: 14px;
          }
        }
      }

      &__control {
        margin-bottom: 0;
        background-color: transparent;

        &:hover {
          background-color: #e5e6eb;
        }
      }

      &__indicators {
        display: none;

        @media ($tablet) {
          display: flex;
        }
      }

      &__single-value {
        .selector-with-icons__text {
          width: calc(3ch + 4px);
          text-transform: uppercase;
        }

        .selector-with-icons__icon {
          border: 1px solid $blue-grey-20;
          box-sizing: border-box;
          border-radius: 4px;

          svg {
            margin-bottom: -3px;
          }
        }
      }

      &__value-container {
        height: 50px;
        align-items: baseline;
      }

      &__menu {
        width: 240px;
        background-color: $white;
      }

      &__option {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &__userbar {
    display: flex;
    align-items: center;
    gap: 3px;

    .navigation-bar__link {
      font-size: 12px;
      @include bold;
      padding: 0;
      color: $blue-grey;
      display: inline;
      width: auto;
      height: auto;

      @media ($tablet) {
        padding: 14px 15px;
      }
      
      @media ($desktop-hd) {
        font-size: 16px;
        padding: 14px 20px;
      }
    }
  }

  &__messages {
    display: inline-block;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: url('../../images/chat-icon.svg') no-repeat center;
    background-size: 24px 24px;
    border: none;
    position: relative;
  }

  &__userbar-action {
    position: relative;
  }

  &__userbar-arrow {
    display: inline-block;
    width: 20px;
    height: 20px;
    transition-duration: 300ms;
    color: $blue-grey-50;
  }

  &__userbar-container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition-duration: 300ms;
    cursor: pointer;

    &:hover {
      background-color: $blue-grey-25;
      color: $blue-grey;
    }

    &--opened {
      background-color: $blue-grey-25;
      color: $blue-grey;

      & .page-header__userbar-arrow {
        transform: rotate(180deg);
      }

      & .page-header__userbar-arrow path {
        fill: $secondary-60;
      }
    }
  }

  &__user-menu {
    width: 180px;
    background-color: $blue-grey;
    border-radius: 16px;
    position: absolute;
    top: 70px;
    right: 0;
    overflow: hidden;
  }

  &__user-menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__user-menu-link {
    display: block;
    padding: 13px 16px 13px 51px;
    @include bold;
    color: $white;
    text-decoration: none;
    position: relative;

    &:hover {
      background-color: $blue-grey-70;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 18px;
      width: 20px;
      height: 20px;
    }

    &--logout {
      &::before {
        background-position: center;
        background-size: 100%;
        background: url('../../images/logout-icon.svg') no-repeat;
      }
    }

    &--settings {
      &::before {
        background-position: center;
        background-size: 100%;
        background: url('../../images/gear.png') no-repeat;
      }
    }

    &--support {
      &::before {
        background-position: center;
        background-size: 100%;
        background: url('../../images/question-mark-circle-outline.svg') no-repeat;
      }
    }
  }

  &__user-name {
    display: none;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 500;
  }

  &__avatar-wrapper {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    border: 2px solid $blue-grey-20;
    border-radius: 50%;
    overflow: hidden;
  }

  &__avatar {
    width: 100%;
  }

  &__sign-up-btn {
    padding: 15px 20px;

    @media (max-width: 767px) {
      font-size: 0;
      padding: 15px;

      & svg {
        margin-right: 0;
      }
    }
  }

  &__collaborate-btn {
    display: none;
    padding: 17px 20px;
    font-size: 14px;

    & svg {
      display: none;
    }
  }
}

@media ($tablet) {

  .page-header {

    &__userbar {
      gap: 16px;
    }

    &__user-name {
      display: inline;
    }

    &__userbar-container {
      padding: 8px 6px 8px 8px;
    }
  }
}

@media ($desktop) {
  .page-header {
    position: relative;
    z-index: 2;
    padding: 0;

    &--transparent {
      background: none;
      border-bottom: 1px solid $blue-grey-70;
      color: $white;
      padding: 12px 0;

      & .navigation-bar__link,
      & .page-header__blog-link {
        color: white;
      }

      & .page-header__logo {
        background: url('../../images/logo-white.svg') no-repeat center;
      }

      & .page-header__messages {
        background: url('../../images/chat-icon-white.svg') no-repeat center;
      }

      & .page-header__userbar-arrow {
        & path {
          fill: $white;
        }
      }

      & .page-header__language-selector .selector-with-icons__control {
        &:hover .selector-with-icons__single-value {
          color: $blue-grey;
        }

        .selector-with-icons__single-value {
          color: $white;
        }
      }

      & .page-header__language-selector,
      .selector-with-icons__single-value,
      .selector-with-icons__icon {
        border: none;
      }
    }

    &--white {
      & .landing-navigation__nav-link {
        color: $blue-grey;
      }
    }

    &__wrapper {
      padding: 8px 0;
      position: relative;
    }

    &__logo-link {
      position: absolute;
      left: 50%;
      margin-left: -74px;
    }

    &__logo {
      width: 148px;
      height: 43px;
      background-size: 148px 43px;
    }

    &__userbar-container {
      &:hover {
        background-color: $blue-grey-25;
        opacity: 0.9;
      }

      &--closed:hover {
        & .page-header__userbar-arrow path {
          fill: $blue-grey;
        }
      }
    }

    &__sign-up-btn {
      margin-right: 8px;
    }

    &__userbar {
      height: 56px;
    }

    &__user-name {
      margin-right: 14px;

      font-size: 14px;
    }

    &__avatar-wrapper {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    &__collaborate-btn {
      display: block;
    }
  }
}

.flags-bar {
  margin-right: 15px;
  display: flex;
  width: 52px;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}
