.next-step-button {
  @include bold;
  color: $secondary;
  padding: 15px 20px;
  border-radius: 12px;
  background: none;
  border: 2px solid $secondary;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    color: $white;
    background-color: $secondary;
  }

  &:active {
    background-color: $secondary-60;
    border-color: $secondary-60;
  }
}
