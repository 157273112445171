.chat-info {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background-color: $grey-10;

  &__container {
    height: 100%;
    margin-top: 64px;
    padding-right: 18px;
    padding-bottom: 48px;
    padding-left: 18px;
    overflow-y: auto;
    overflow-x: clip;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 13px 18px;
    background-color: $white;
  }

  &__go-back-btn {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background: url('../../../images/arrow-left-icon.svg') no-repeat center;
    background-size: 100%;
    border: none;
  }

  &__title {
    flex-grow: 1;
    margin: 0;
    font-size: 16px;
    line-height: 19px;
  }

  &__profile {
    display: flex;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid $blue-grey-20;
  }

  &__streamer-avatar-wrapper {
    width: 96px;
    height: 96px;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__streamer-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:before {
      content: ' ';
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-image: url('../../../images/default-streamer-avatar.png');
      background-size: 100%;
    }
  }

  &__nickname-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__nickname {
    display: inline-block;
    margin-bottom: 4px;
    @include bold;
    font-size: 24px;
    line-height: 29px;
  }

  &__status {
    position: relative;
    padding-left: 18px;
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-70;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 2px;
      width: 8px;
      height: 8px;
      margin-top: -4px;
      background-color: $blue-grey-50;
      border-radius: 50%;
    }

    &--online::before {
      background-color: $green-50;
    }
  }

  &__section {
    padding: 24px 0;
    border-bottom: 1px solid $blue-grey-20;

    &:last-child {
      border-bottom: none;
    }
  }

  &__section-title {
    margin: 0 0 12px;
    font-size: 20px;
    line-height: 24px;
  }

  &__options-button {
    @include bold;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 17px;
    color: $blue-grey-70;
    background-color: $grey-20;
    border-radius: 12px;

    & svg {
      margin-right: 8px;
    }
  }

  &__twitch-link {
    margin-bottom: 8px;
    text-decoration: none;
  }

  &__suggest-collab-button {
    width: 100%;
    border: none;
    cursor: pointer;
  }

  &__info-list {
    width: 100%;
    padding-bottom: 58px;
  }

  &__info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid $blue-grey-20;

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__games-list {
    margin: 5px 0 0;

    & .streamer-info__more-games-list {
      width: 220px;
      top: calc(100% + 10px);
    }
  }

  &__info-title {
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-60
  }

  &__info-value {
    font-size: 16px;
    line-height: 19px;
    @include bold;
    text-align: end;
  }
}

@media ($tablet) {
  .chat-info {
    top: 50%;
    left: 50%;
    width: 360px;
    height: 600px;
    margin-top: -300px;
    margin-left: -180px;
    overflow: hidden;
    background-color: $grey;
    border: 1px solid #eaebf0;
    border-radius: 24px;

    &__background {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: $blue-grey-70;
      opacity: 0.5;
    }

    &__container {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      padding: 0 24px 40px;
    }

    &__header {
      position: static;
      padding: 15px;
    }

    &__close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      max-width: 40px;
      height: 40px;
      padding: 0;
      background-color: $grey-15;
      background-image: url('../../../images/close-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      border-radius: 12px;
      cursor: pointer;
      transition-duration: 300ms;
    }

    &__info-list {
      padding-bottom: 0;
    }

    &__options-button {
      background-color: $grey-15;
    }
  }
}

@media ($desktop) {
  .chat-info {
    position: static;
    z-index: 0;
    min-width: 272px;
    max-width: 272px;
    height: auto;
    margin: 0;

    &__container {
      padding-bottom: 0;

      &--empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 24px;
      }
    }

    &__smile-wrapper {
      width: 160px;
      height: 160px;
      background-color: $grey-15;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      & img {
        width: 96px;
        height: 96px;
      }
    }

    &__message {
      @include bold;
      text-align: center;
      color: $blue-grey;
      font-size: 20px;
      line-height: 120%;
      margin: 0;
      margin-bottom: 24px;
    }

    &__profile {
      flex-direction: column;
      justify-content: center;
    }

    &__streamer-avatar-wrapper {
      width: 160px;
      height: 160px;
      margin-right: 0;
      margin-bottom: 12px;
    }

    &__nickname-wrapper {
      align-items: center;
    }

  }
}

@media ($desktop-hd) {
  .chat-info {
    min-width: 352px;
    max-width: 352px;
  }
}