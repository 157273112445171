.streamers {
  position: relative;

  &__card {
    background-color: $white;
    width: 100%;
    border-radius: 16px;
    padding: 16px 24px;
    margin-bottom: 14px;

    &--not-logged-in {
      &:nth-last-child(1) {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }

  &__test {
    position: absolute;
    top: 0;
  }

  &__login-as-admin-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey-15;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    margin-right: 8px;
    cursor: pointer;
    transition-duration: 300ms;

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;

      & path {
        fill: $white;
      }
    }
  }

  &__twitch-link {
    width: 49px;
    height: 49px;
    padding: 0;
    margin-right: 8px;
    font-size: 0;

    & svg {
      margin-right: 0;
    }
  }

  &__chat-link, &__chat-link-short {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey-15;
    border-radius: 12px;
    width: 49px;
    height: 49px;
    font-size: 0;
    transition-duration: 300ms;

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
      color: $white;

      & path {
        fill: $white;
      }
    }
  }

  &__login-message {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 404px;
  }

  &__login-text {
    margin: 0;
    font-size: 24px;
    text-align: center;
    line-height: 29px;
    margin-bottom: 24px;

    & span {
      color: $secondary;
    }
  }

  &__login-button {
    width: auto;
  }
}

@media ($tablet) {
  .streamers {

    &__login-message {
      height: 348px;
    }
  }
}

@media ($desktop) {
  .streamers {
    &__list {
      &--not-logged-in {
        min-height: 454px;
      }
    }

    &__card {
      padding: 24px;
      transition-duration: 400ms;

      &--not-logged-in {
        &:nth-last-child(1),
        &:nth-last-child(2) {
          opacity: 0.2;
          pointer-events: none;
        }
      }

      &:hover {
        box-shadow: 0px 4px 32px rgba(42, 53, 110, 0.12);
      }
    }

    &__chat-link {
      @include bold;
      font-size: 14px;
      margin-right: 8px;
      width: auto;
      height: auto;
      padding: 14px 20px;
      text-decoration: none;
      color: $blue-grey;

      & svg {
        margin-right: 8px;
      }
    }

    &__chat-link-short {
      margin-right: 8px;
    }

    &__login-message {
      height: 454px;
    }

    &__login-text {
      font-size: 56px;
      line-height: 67px;
      margin-bottom: 32px;
    }
  }
}
