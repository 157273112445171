.my-collab-requests {
  margin-top: 88px;
  margin-bottom: 130px;

  &__container {
    @include container;
  }

  &__title {
    margin: 0 0 24px;
    font-size: 32px;
    line-height: 38px;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 14px 24px;
    border: 1px solid #eaebf0;
    border-radius: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-description {
    margin: 0;
    font-size: 14px;
    color: $blue-grey-70;
  }

  &__info-counter {
    @include bold;
    font-size: 24px;
    color: $blue-grey-70;
  }

  &__warning-message {
    padding: 24px 16px 24px 64px;
    border: 2px solid $secondary-60;
    border-radius: 16px;
    position: relative;
    margin-bottom: 40px;

    &::before {
      content: '';
      position: absolute;
      width: 32px;
      height: 32px;
      top: 20px;
      left: 16px;
      background-image: url('../../../images/circle-warning-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }

  &__warning-title {
    @include bold;
    font-size: 20px;
    margin: 0;
    margin-bottom: 8px;
    color: $red-40;
  }

  &__warning-description {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: $red-40;
    margin-bottom: 16px;
  }

  &__warning-button {
    @include bold;
    cursor: pointer;
    border: none;
    color: $red-40;
    padding: 15px 20px;
    font-size: 14px;
    background: rgba(236, 43, 45, 0.1);
    border-radius: 12px;
    transition-duration: 300ms;

    &:hover {
      background-color: $red-40;
      color: $white;
    }

    &:active {
      background-color: $red-10;
      color: $red-50;
    }
  }

  &__switcher {
    margin-bottom: 34px;
  }

  &__switcher-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #eaebf0;
  }

  &__switch-button {
    width: calc(50% - 5px);
    padding: 15px 0;
    @include bold;
    font-size: 15px;
    text-align: left;
    color: $blue-grey-60;
    background: none;
    border: none;
    cursor: pointer;

    &--current {
      padding-bottom: 12px;
      color: $blue-grey;
      border-bottom: 3px solid $blue-grey;
      cursor: auto;
    }

    @media (min-width: 375px) {
      padding: 20px 0 20px 12px;
      font-size: 18px;
    }
  }

  &__switcher-description {
    margin: 0;
    font-size: 14px;
    line-height: 140%;
    color: $blue-grey-70;

    @media ($desktop) {
      font-size: 16px;
    }
  }

  &__filter-label {
    @include bold;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 16px;
  }

  &__checkbox-list {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $blue-grey-20;
  }

  &__list-counter {
    font-size: 16px;
    color: $blue-grey-70;
  }

  &__collab-list {
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__collab-item {
    margin-bottom: 12px;
    padding: 24px 16px;
    background-color: $white;
    border-radius: 16px;
    transition-duration: 300ms;

    &:hover {
      box-shadow: 0px 4px 32px rgba(42, 53, 110, 0.12);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__streamer-info-container {
    display: flex;
  }

  &__streamer-info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  &__streamer-info-wrapper {
    display: flex;
    flex-direction: column;

    &--modal {
      flex-grow: 1;
    }
  }

  &__avatar-wrapper {
    margin-right: 16px;
  }

  &__nickname-wrapper {
    margin-bottom: 12px;
  }

  &__streamer-nickname {
    margin-right: 0;
  }

  &__status-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__status {
    position: relative;
    display: block;
    margin-bottom: 6px;
    padding-left: 12px;
    @include bold;
    font-size: 12px;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-radius: 50%;
      background-color: $blue-grey;
    }

    &--NO_RESPONSE,
    &--REJECTED,
    &--CANCELLED {
      color: $red-40;

      &::before {
        background-color: $red-40;
      }
    }

    &--PENDING {
      color: $blue-50;

      &::before {
        background-color: $blue-50;
      }
    }

    &--COMPLETED,
    &--ACCEPTED {
      color: $green-50;

      &::before {
        background-color: $green-50;
      }
    }
  }

  &__request-date {
    display: block;
    font-size: 12px;
    color: $blue-grey-70;
  }

  &__request-reply-wrapper {
    margin-bottom: 24px;
  }

  &__reply {
    display: block;
    font-size: 12px;
    color: $blue-grey-50;
    margin-bottom: 4px;
  }

  &__reply-text {
    margin: 0;
    font-size: 12px;
    line-height: 17px;
    color: $blue-grey-70;
  }

  &__response-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid $blue-grey-20;
    margin-bottom: 24px;
  }

  &__response-button {
    width: calc(50% - 4px);
    border: none;
    padding: 16px;
    font-size: 12px;
    @include bold;
    border-radius: 12px;
    transition-duration: 300ms;
    cursor: pointer;

    & svg {
      display: none;
    }
  }

  &__completion-button {
    color: $green-40;
    background-color: $green-5;

    &:hover {
      background-color: $green-40;
      color: $white;

      & path {
        fill: $white;
      }
    }

    &:active {
      background-color: $green-10;
      color: $green-50;

      & path {
        fill: $green-50;
      }
    }
  }

  &__cancel-button {
    color: $red-40;
    background-color: $red-5;

    svg g {
      opacity: 1;
    }

    &:hover {
      background-color: $red-40;
      color: $white;

      & rect {
        fill: $white;
      }
    }

    &:active {
      background-color: $red-10;
      color: $red-50;

      & rect {
        fill: $red-50;
      }
    }
  }

  &__view-request-button {
    width: 100%;
    color: $white;
    @include bold;
    background-color: $primary-50;
    border-radius: 12px;
    border: none;
    padding: 16px 24px;
    cursor: pointer;
    transition-duration: 300ms;

    &--desktop {
      display: none;
    }

    &:hover {
      background-color: $primary-40;
    }

    &:active {
      background-color: $primary-60;
    }
  }
}

@media ($tablet) {
  .my-collab-requests {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      margin-bottom: 0;
    }

    &__info-wrapper {
      flex-direction: row;
      margin-bottom: 0;
    }

    &__info {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;

      &:first-child {
        margin-right: 8px;
      }
    }

    &__info-description {
      order: 1;

      & span {
        display: none;
      }
    }

    &__warning-message {
      margin-bottom: 56px;
    }

    &__switcher-wrapper {
      justify-content: flex-start;
    }

    &__switch-button {
      width: auto;
      margin-right: 32px;
      padding: 17px 0;
      font-size: 24px;

      &--current {
        padding-bottom: 14px;
      }
    }

    &__collab-list {
      margin-bottom: 32px;
    }

    &__streamer-info {
      margin-bottom: 0;
      align-items: center;
      justify-content: space-between;
    }

    &__status-wrapper {
      flex-direction: row;
      align-items: center;
    }

    &__status {
      padding-right: 28px;
      margin-bottom: 0;

      &::after {
        content: '';
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -2px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $blue-grey-70;
      }
    }

    &__request-date {
      font-size: 14px;
    }

    &__view-request-button {
      width: auto;
      font-size: 14px;
      padding: 15px 20px;

      &--mobile {
        display: none;
      }

      &--desktop {
        display: inline-block;
      }
    }

    &__request-reply-wrapper {
      margin-top: 25px;
      margin-bottom: 0;
      display: flex;
    }

    &__reply {
      display: inline-block;
      font-size: 14px;
      margin-right: 12px;
    }

    &__reply-text {
      font-size: 14px;
    }

    &__response-buttons-wrapper {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
      padding-top: 24px;
      border-top: 1px solid $blue-grey-20;
      justify-content: flex-end;
      margin-top: 24px;
    }

    &__response-button {
      display: flex;
      align-items: center;
      width: auto;
      padding: 15px 20px;
      font-size: 14px;

      & svg {
        display: inline-block;
        margin-right: 8px;
      }
    }

    &__completion-button {
      margin-right: 8px;
    }
  }
}

@media ($desktop) {
  .my-collab-requests {
    margin-top: 30px;
    
    & .Collapsible__trigger {
      display: none;
    }

    & .Collapsible__contentOuter {
      margin-top: 0;
    }

    & .Collapsible__contentInner {
      border-radius: 12px;
      padding: 32px 24px;
    }

    &__title {
      font-size: 56px;
      line-height: 67px;
    }

    &__main-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__switch-button {
      padding: 15px 0;

      &--current {
        padding-bottom: 12px;
      }
    }

    &__filters-wrapper {
      width: 272px;
      margin-right: 32px;

      @media ($desktop-hd) {
        width: 352px;
      }
    }

    &__requests-wrapper {
      flex-grow: 1;
    }

    &__collab-list {
      margin-top: 0;
    }
  }
}
