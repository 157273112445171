.about {
  background-color: $white;
  padding: 56px 0;

  &__wrapper {
    @include container;
  }

  &__description {
    color: $blue-grey-70;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 40px;
  }

  &__video-description {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 4px;
  }

  &__player {
    width: 100%;
    height: calc(100vw * 0.564);
    margin-bottom: 40px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    & > iframe {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__cta {
    display: block;
    @include bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;

    & span {
      color: $secondary;
    }
  }
}

@media ($tablet) {
  .about {
    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__collaborate-btn {
      align-self: flex-start;
    }

    &__player {
      height: 360px;
    }
  }
}

@media ($desktop) {
  .about {
    padding: 104px 0;

    &__description {
      width: 880px;
      font-size: 24px;
      line-height: 34px;
      margin-left: 152px;
      margin-bottom: 48px;
    }

    &__video-description {
      font-size: 16px;
      line-height: 22px;
      margin-left: 152px;
    }

    &__player {
      height: 507px;
      border-radius: 32px;
      margin-bottom: 56px;
    }

    &__cta {
      font-size: 56px;
      line-height: 67px;
      margin-left: 152px;
      margin-bottom: 24px;
      width: 885px;
    }

    &__collaborate-btn {
      margin-left: 152px;
    }
  }
}

@media ($desktop-hd) {
  .about {
    &__wrapper {
      padding: 0 190px;
    }

    &__description,
    &__video-description,
    &__cta,
    &__collaborate-btn {
      margin-left: 192px;
    }

    &__video-description {
      margin-bottom: 17px;
    }

    &__player {
      height: 480px;
    }
  }
}

.ytp-pause-overlay {
  display: none;
}
