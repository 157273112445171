.Collapsible {
  &__trigger {
    position: relative;

    display: inline-block;
    width: 100%;
    padding: 16px;

    font-size: 14px;
    color: $blue-grey;
    cursor: pointer;
    border: 1px solid $blue-grey-20;
    border-radius: 12px;

    transition-duration: 300ms;

    @include medium;

    &::after {
      content: '';
      position: absolute;
      right: 15px;

      width: 20px;
      height: 20px;
      margin-top: -2px;

      -webkit-mask-image: url('../../../images/arrow-bottom-icon.svg');
      mask-image: url('../../../images/arrow-bottom-icon.svg');
      background-color: $blue-grey-70;
      background-position: center;
      background-size: 100%;

      transition-duration: 300ms;
    }

    &.is-closed {
      background-color: $grey;
    }

    &.is-open {
      color: $white;

      background-color: $blue-grey;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &::after {
        -webkit-mask-image: url('../../../images/arrow-bottom-icon.svg');
        mask-image: url('../../../images/arrow-bottom-icon.svg');
        background-color: $secondary;
        transform: rotate(180deg);
      }
    }

    &.is-open + .Collapsible__contentOuter {
      overflow: visible !important;
    }
  }

  &__contentOuter {
    margin-top: -16px;
  }

  &__contentInner {
    padding: 40px 16px 24px 16px;

    border: 1px solid $blue-grey-20;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}
