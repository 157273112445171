.footer {
  padding: 40px 0 80px;
  background-color: $white;

  &__wrapper {
    @include container;
  }

  &__top {
    padding-bottom: 32px;
    border-bottom: 1px solid $blue-grey-20;
    margin-bottom: 32px;
  }

  &__logo {
    width: 120px;
    margin-bottom: 32px;
  }

  &__contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__socials-list {
    display: flex;
    align-items: center;
    border-right: 1px solid $blue-grey-20;
    margin-right: 12px;
    padding-right: 16px;

    @media (max-width: 434px) {
      border: none;
      margin-right: 0;
    }

    @media (max-width: 421px) {
      margin-bottom: 16px;
    }
  }

  &__social-item {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    height: 48px;
  }

  &__social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @include bold;
    font-size: 16px;
    line-height: 19px;
    color: $blue-grey;
  }

  &__email {
    text-decoration: none;
    color: $blue-grey;
    @include bold;
    font-size: 16px;
  }

  &__discord-btn {
    width: 191px;
    margin-right: 16px;
    background: $discord-color;

    a {
      width: 100%;
      height: 100%;
      color: $white;
      font-size: 14px;

      svg {
        margin-right: 10px;
      }
    }
  }

  &__twitter-btn {
    width: 48px;
    background: $grey-15;

    a {
      width: 100%;
      height: 100%;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__copyright {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 32px;
  }

  &__privacy-link {
    color: $blue-grey;
  }

  &__designer {
    color: $blue-grey-60;
    font-size: 16px;
  }

  &__designer-link {
    color: $blue-grey-60;
  }
}

@media ($desktop) {
  .footer {
    padding: 40px 0;

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__logo {
      margin-bottom: 0;
      width: 148px;
      margin-right: 32px;
    }

    &__contacts {
      flex-grow: 1;
      justify-content: flex-start;
    }

    &__socials-list {
      padding: 0 32px;
      margin-bottom: 0;
      border-left: 1px solid $blue-grey-20;
      margin-right: 32px;
    }

    &__social-item {
      &:last-child {
        margin-right: 0;
      }
    }

    &__buttons {
      display: flex;
    }

    &__sign-up-btn {
      margin-right: 8px;
      padding: 15px 20px;
      font-size: 14px;
    }

    &__collaborate-btn {
      padding: 15px 20px;
      font-size: 14px;

      & svg {
        display: none;
      }
    }

    &__bottom {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__copyright {
      margin-bottom: 0;
      order: 1;
    }
  }
}
