.privacy-policy {
  margin: 120px 0;
  &__container {
    @include container;
  }

  &__main-title {
    font-size: 32px;
  }

  &__title {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 18px;
  }

  &__paragaph {
    line-height: 130%;
  }

  &__link {
    color: $blue-grey;
  }

  &__list {
    list-style: inside;
  }

  &__item {
    margin-bottom: 10px;
  }

  & + .footer {
    margin-bottom: 48px;
  }
}

@media ($desktop) {
  .privacy-policy {
    margin: 130px 0;

    &__main-title {
      font-size: 56px;
      margin-bottom: 60px;
    }

    &__title {
      font-size: 36px;
      margin-top: 50px;
      margin-bottom: 22px;
    }

    &__paragaph {
      font-size: 18px;
    }

    & + .footer {
      margin-bottom: 0;
    }
  }
}
