//colors
$primary-60: #7f10d4;
$primary-50: #9c32ef;
$primary-40: #b35ff3;
$secondary: #fb6269;
$secondary-hover: #fd8288;
$secondary-60: #fa303a;
$blue-grey: #2a356e;
$blue-grey-70: #4a5384;
$blue-grey-60: #6a729a;
$blue-grey-50: #949ab7;
$blue-grey-40: #bfc2d3;
$blue-grey-30: #d4d7e2;
$blue-grey-25: #e5e6eb;
$blue-grey-20: #eaebf0;
$grey: #f9f9f8;
$grey-10: #f4f5f8;
$grey-15: #f2f2f1;
$grey-20: #eaeaea;
$white: #ffffff;
$lime: #dce775;
$teal: #80cbc4;
$green-50: #4caf50;
$green-40: #66bb6a;
$green-10: #c8e6c9;
$green-5: #edf6ed;
$green: #a5d6a7;
$red-50: #fb362d;
$red-40: #f64a4a;
$red-10: #ffccd1;
$red-5: #feeaeb;
$blue-50: #2196f3;
$discord-color: #5865f2;
$discord-hover-color: #828cf7;

//breakpoints
$mobile: 'max-width: 767px';
$tablet: 'min-width: 768px';
$desktop: 'min-width: 1280px';
$desktop-hd: 'min-width: 1600px';
$retina: 'min-resolution: 1.5dppx),(min-resolution: 144dpi';
