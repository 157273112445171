@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'Roboto';

  src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: 'Roboto';

  src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
  url('../fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: 'Roboto';

  src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/Roboto-Bold.woff') format('woff');
}

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  height: 100%;
  color: $blue-grey;
  background-color: $grey;
  overflow-x: hidden;
  @include regular;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1 auto;
}

.scroll-off {
  overflow: hidden;
}

ul,
ol {
  margin: 0;
  padding: 0;

  list-style: none;
}

.h2-title {
  @include bold;
  font-size: 32px;
  color: $blue-grey;
  line-height: 38px;
  margin: 0;
  margin-bottom: 32px;

  @media ($desktop) {
    font-size: 56px;
    line-height: 68px;
    margin-bottom: 56px;
  }

  @media ($desktop-hd) {
    font-size: 80px;
    line-height: 96px;
  }
}

.fake-login {
  background-color: $red-5;
  
  &__wrapper {
    @include container;
    position: relative;
  }

  &__notice {
    color: #FF0000;
    @include bold;
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 16px;
    margin: 0;

    @media ($tablet) {
      right: 0;
    }

    @media ($desktop) {
      top: 80px;
    }
  }
}
