.event-details {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;

  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($blue-grey-70, 0.5);
  }

  &__modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
  }

  &__modal {
    width: calc(100% - 32px);
    background-color: $grey;
    border-radius: 16px;
    position: absolute;
    top: 100px;
    left: 16px;
    padding: 32px 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  &__header-time {
    margin-bottom: 20px;
    font-size: 14px;
    color: $blue-grey-60;
  }

  &__title {
    @include bold;
    font-size: 28px;
    line-height: 38px;
    margin: 0;
  }

  &__button {
    border-radius: 12px;
    background-color: $grey-15;
    border: none;
    cursor: pointer;
    transition-duration: 300ms;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
    }
  }
  
  &__span_10 {
    margin-top: 10px;
  }

  &__close-button {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    height: 40px;
    display: flex;
  }

  &__copy-button {
    opacity: 0.5;
  }

  &__section-title {
    @include bold;
    font-size: 21px;
  }

  &__section-text {
    @include bold;
    font-size: 16px;
    color: $blue-grey-60;
  }

  &__message-to-text {
    @include bold;
    font-size: 16px;
    color: $blue-grey-60;
    margin-top: 25px;
  }

  &__section-author {
    margin-bottom: 10px;
  }

  &__streamer-card {
    background-color: $white;
    width: 100%;
    border-radius: 16px;
    padding: 16px 24px;
    margin-bottom: 24px;
  }

  &__streamer-card-narrow {
    background-color: $white;
    width: 100%;
    border-radius: 16px;
    padding: 8px 24px;
    margin-bottom: 10px;
  }

  &__streamer-info-about {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
  }

  &__nickname {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: 527px) {
      font-size: 20px;
      width: auto;
    }
  }

  &__twitch-link {
    font-size: 12px;
    margin-top: -3px;
    margin-left: 55px;

    & svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    @media (min-width: 375px) {
      font-size: 14px;
      margin-left: 64px;

      & svg {
        width: 19px;
        height: 19px;
        margin-right: 8px;
      }
    }

    @media (min-width: 527px) {
      margin: 0;
    }
  }

  &__spoilers-container {
    padding-left: 32px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 11px;
      width: 5px;
      height: calc(100% - 40px);
      border-left: 1px dashed $blue-grey-30;
    }
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 14px;
  }

  &__input-counter {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  &__input-label {
    font-size: 14px;
    color: $blue-grey-60;
    font-weight: 500;
    background-color: $white;
    padding: 0 4px;
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 2;
    margin-left: 12px;
    transition-duration: 300ms;
  }

  &__input {
    @include bold;
    border-radius: 12px;
    width: 100%;
    height: 51px;
    outline: none;
    border: 1px solid $blue-grey-40;
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
    color: $blue-grey;

    &.error {
      border-color: $red-40;
    }

    &.success {
      border-color: $green-40;
    }

    &:hover {
      border-width: 2px;
      border-color: $blue-grey-40 !important;
    }

    &:focus {
      border-width: 2px;
      border-color: $primary-50 !important;
    }

    &::placeholder {
      color: $blue-grey-50;
      opacity: 0;
      transition-delay: 100ms;
      transition-duration: 200ms;
    }

    &:hover::placeholder {
      opacity: 1;
    }

    &--textarea {
      height: auto;
      min-height: 104px;
      resize: vertical;
      padding-bottom: 32px;
    }
  }

  &__input-wrapper input.filled + label,
  &__input-wrapper input:focus + label,
  &__input-wrapper input:hover + label,
  &__input-wrapper textarea.filled + label,
  &__input-wrapper textarea:focus + label,
  &__input-wrapper textarea:hover + label {
    top: -5px;
    font-size: 12px;
    font-weight: 400;
    color: $blue-grey-50;
  }

  &__error-message {
    display: block;
    font-size: 12px;
    line-height: 17px;
    color: $red-40;
    margin-bottom: 14px;
  }

  &__games-list {
    margin: 0;
  }

  &__example {
    @include bold;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    padding-left: 28px;
    position: relative;
    margin-bottom: 10px;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background-size: 100%;
      background: url('../../../images/info-circle.svg') no-repeat center;
    }
  }

  &__example-text {
    font-size: 12px;
    line-height: 17px;
    color: $blue-grey-70;
    padding-bottom: 32px;
    border-bottom: 1px solid $blue-grey-20;
    margin: 0 0 16px;
  }
  
  &__private_checkbox {
    float: right;
  }
  
  &__private_label {
    float: right;
    color: #2196f3;
    margin-left: 15px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
  }

  &__description {
    @include bold;
    font-size: 14px;
    line-height: 17px;
    color: $blue-grey;
    margin: 0 0 24px;
  }

  &__add-game-wrapper {
    display: inline-block;
    position: relative;
  }

  &__add-game-button {
    @include bold;
    align-items: center;
    justify-content: center;
    padding: 7px 12px;
    font-size: 12px;
    line-height: 14px;
    color: $blue-grey;
    background-color: white;
    border: 1px solid $blue-grey-70;
    border-radius: 8px;
    margin: 2px;
    white-space: nowrap;
    cursor: pointer;
  }

  &__games-wrapper {
    padding-bottom: 24px;
    border-bottom: 1px solid $blue-grey-20;
    margin-bottom: 16px;
  }

  &__game-selector-popup {
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    z-index: 5;
    width: 255px;
    background-color: $white;
    box-shadow: 0px 4px 32px rgba(42, 53, 110, 0.12);
    border-radius: 16px;
    padding-top: 16px;
  }

  &__games-input {

    &__control {
      width: 223px !important;
      margin: 0 auto !important;
      margin-bottom: 16px !important;
      border: 1px solid $blue-grey-40 !important;

      &:hover {
        box-shadow: none !important;
      }
    }

    &__placeholder {
      color: $blue-grey-50 !important;
    }

    &__indicator {
      display: none !important;
    }

    &__menu {
      position: static !important;
      top: auto !important;
      z-index: auto !important;
      background-color: $white !important;
      margin-top: 16px !important;
      box-shadow: none !important;
    }

    &__menu-list {
      border: none !important;
    }

    &__menu-notice {

      &--no-options {
        background-color: $white !important;
      }
    }
  }

  &__game-selector-input {
    display: block;
    width: 223px;
    margin: 0 auto 16px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $blue-grey-40;

    &::placeholder {
      color: $blue-grey-50;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__info-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__games-item-button {
    @include bold;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 7px 12px;
    font-size: 12px;
    line-height: 14px;
    color: $blue-grey;
    background-color: white;
    border: 1px solid $blue-grey-70;
    border-radius: 8px;
    margin: 2px;
    white-space: nowrap;
  }

  &__send-request-button {
    width: 100%;
    font-size: 14px;
    color: $white;
    @include bold;
    background-color: $primary-50;
    border: none;
    padding: 15px 20px;
    border-radius: 12px;
    margin-top: 24px;
    transition-duration: 300ms;
    cursor: pointer;
  }

  &__indent {
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    pointer-events: none;
  }
}

@media ($tablet) {
  .event-details {
    &__modal {
      width: 736px;
      top: 100px;
      left: 50%;
      margin-left: -368px;
      padding: 40px;
    }

    &__header {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 32px;
      line-height: 38px;
    }

    &__streamer-card {
      border-radius: 24px;
      padding: 24px;
      margin-bottom: 20px;
    }

    &__spoilers-container {
      padding-left: 64px;

      &::before {
        left: 19px;
      }
    }

    &__example {
      font-size: 16px;
      line-height: 19px;
      padding-left: 32px;

      &::before {
        width: 24px;
        height: 24px;
        margin-top: -12px;
      }
    }

    &__example-text {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
