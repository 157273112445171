.quote {
  background-color: $blue-grey;

  &__container {
    @include container;
    padding-top: 56px;
    padding-bottom: 56px;
  }

  &__text {
    @include bold;
    font-size: 24px;
    line-height: 29px;
    height: 203px;
    color: $white;
    margin: 0 0 32px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;

    & span {
      color: $secondary;
    }
  }

  &__info {
    padding-top: 32px;
    border-top: 1px solid $blue-grey-70;
  }

  &__author-name {
    display: inline-block;
    @include bold;
    font-size: 20px;
    line-height: 24px;
    color: $white;
    padding-left: 55px;
    position: relative;
    margin-bottom: 32px;

    &::before {
      content: '';
      position: absolute;
      width: 48px;
      height: 48px;
      background: url('../../../images/quotes.svg') no-repeat center;
      background-size: 100%;
      left: 0;
      top: -50%;
    }
  }
}

@media ($tablet) {
  .quote {

    &__text {
      padding: 0 48px;
    }

    &__author-name {
      margin-bottom: 0;
      margin-top: 16px;
    }

    &__collaborate-btn {
      width: 300px;
      margin-left: auto;
      transform: translateY(-40px);
    }

    &__arrow {
      top: 100px !important;
      background: none !important;
      transition-duration: 300ms;

      & svg {
        width: 50px !important;
        height: 50px !important;
      }

      & path {
        fill: $white;
      }

      &--prev {
        left: 0 !important;
      }

      &--next {
        right: 0 !important;
      }
    }
  }
}

@media ($desktop) {
  .quote {
    padding: 0;

    &__container {
      padding: 104px 152px;
    }

    &__text {
      font-size: 48px;
      line-height: 58px;
      -webkit-line-clamp: 5;
      height: 290px;
    }

    &__arrow {
      top: 145px !important;
    }

    &__author-name {
      font-size: 24px;
      margin-top: 22px;
    }

    &__collaborate-btn {
      transform: translateY(-46px);
    }
  }
}

@media ($desktop-hd) {
  .quote {
    &__container {
      padding-left: 192px;
      padding-right: 192px;
    }
  }
}
