.my-gyre-events-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100vh;

  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($blue-grey-70, 0.5);
  }

  &__modal {
    width: calc(100% - 32px);
    background-color: $grey;
    border-radius: 16px;
    position: absolute;
    top: 100px;
    left: 16px;
    padding: 32px 24px;
    margin-bottom: 100px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &__title {
    @include bold;
    font-size: 32px;
    line-height: 38px;
    margin: 0;
  }

  &__close-button {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: $grey-15;
    border: none;
    cursor: pointer;
    transition-duration: 300ms;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      background-color: $grey-20;
    }
  }

  &__description {
    font-size: 20px;
    line-height: 24px;
    color: $blue-grey-60;
    @include bold;
    margin: 16px 0 32px;
  }

  &__streamer-card {
    background-color: $white;
    padding: 24px 16px;
    border-radius: 16px;
    margin-bottom: 10px;
  }

  &__twitch-link {
    font-size: 0;
    width: 42px;
    height: 42px;
    padding: 0;

    & svg {
      margin-right: 0;
    }

    @media (min-width: 375px) {
      width: auto;
      height: auto;
      font-size: 14px;
      padding: 15px 20px;

      & svg {
        margin-right: 8px;
      }
    }
  }

  &__show-more-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey-15;
    color: $blue-grey-70;
    border-radius: 8px;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition-duration: 300ms;
    margin-bottom: 24px;

    & svg {
      transition-duration: 300ms;
    }

    &--opened {
      & svg {
        transform: rotate(180deg);
      }
    }

    & svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
      color: $white;
    }

    &:active path {
      fill: $white;
    }
  }

  &__games-label {
    display: inline-block;
    font-size: 12px;
    color: $blue-grey-60;
    margin-bottom: 12px;
  }

  & .streamer-info__about,
  .streamer-info__games-list {
    margin-top: 0;
  }

  &__more-content {
    overflow: hidden;
    height: 0;
    margin-top: 20px;

    &--visible {
      overflow: auto;
      height: auto;
    }
  }

  &__reply-label {
    display: inline-block;
    font-size: 14px;
    color: $blue-grey-50;
    margin-bottom: 8px;
  }

  &__streamer-reply {
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-70;
    margin: 0;
  }

  &__status-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }

  &__request-date {
    display: block;
    font-size: 12px;
    color: $blue-grey-70;
  }

  &__status {
    position: relative;
    display: block;
    margin-bottom: 6px;
    padding-left: 12px;
    @include bold;
    font-size: 12px;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-radius: 50%;
      background-color: $blue-grey;
    }

    &--NO_RESPONSE,
    &--REJECTED,
    &--CANCELLED {
      color: $red-40;

      &::before {
        background-color: $red-40;
      }
    }

    &--PENDING {
      color: $blue-50;

      &::before {
        background-color: $blue-50;
      }
    }

    &--COMPLETED,
    &--ACCEPTED {
      color: $green-50;

      &::before {
        background-color: $green-50;
      }
    }
  }

  &__section-title {
    @include bold;
    font-size: 21px;
    margin-bottom: 10px;
  }

  &__comments-title {
    @include bold;
    font-size: 24px;
    color: $blue-grey-60;
    margin: 0;
    padding-bottom: 12px;
    border-bottom: 1px solid $blue-grey-30;
    margin-bottom: 24px;
  }

  &__commentator-name {
    display: block;
    position: relative;
    font-size: 20px;
    line-height: 24px;
    @include bold;
    color: $blue-grey;
    padding-left: 40px;
    margin-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      width: 32px;
      height: 32px;
      left: 0;
      top: 50%;
      margin-top: -16px;
      background-image: url('../../../images/quotes.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }

  &__comment {
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-70;
    margin: 0 0 24px;
    white-space: pre-wrap;

    &--propose {
      margin-bottom: 0;
    }
  }

  &__propose-wrapper {
    margin-bottom: 40px;
  }

  &__propose-title,
  &__stream-preview-title {
    margin: 0;
    margin-bottom: 12px;
    font-size: 20px;
  }

  &__last-stream {
    border: 1px solid $blue-grey-30;
    border-radius: 16px;
    margin-bottom: 32px;
    padding: 16px;
  }

  &__stream-preview-wrapper {
    display: flex;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 8px;
  }

  &__stream-preview {
    object-fit: cover;
  }

  &__stream-preview-title {
    margin-bottom: 24px;
  }

  &__check-stream-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 20px;
    font-size: 14px;
    @include bold;
    color: $blue-grey-70;
    text-decoration: none;
    background-color: $grey-15;
    border-radius: 12px;
    cursor: pointer;
    transition-duration: 300ms;

    & svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
      color: $white;
    }

    &:active path {
      fill: $white;
    }
  }

  &__response-buttons-wrapper {
    display: flex;
    flex-direction: column;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 14px;

      & svg {
        display: inline-block;
        margin-right: 8px;
      }

      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
}

@media ($tablet) {
  .my-gyre-events-modal {
    &__modal {
      width: 736px;
      top: 100px;
      left: 50%;
      margin-left: -368px;
      padding: 40px;
    }

    &__title {
      font-size: 40px;
    }

    &__description {
      margin-top: 40px;
      margin-bottom: 16px;
    }

    &__streamer-card {
      padding: 24px 32px;
      margin-bottom: 10px;

      & .my-collab-requests__streamer-info-wrapper {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      & .streamer-info__nickname {
        margin-bottom: 0;
      }

      & .my-collab-requests__streamer-info {
        margin-bottom: 16px;
      }
    }

    &__show-more-button {
      margin-bottom: 10px;
    }

    &__reply-wrapper {
      display: flex;
      align-items: flex-start;
    }

    &__reply-label {
      margin-bottom: 0;
      margin-right: 85px;
    }

    &__streamer-reply {
      font-size: 16px;
      line-height: 22px;
    }

    &__comments-title {
      margin-bottom: 32px;
    }

    &__commentator-name {
      font-size: 24px;
    }

    &__comment {
      font-size: 16px;
      line-height: 22px;
    }

    &__status-wrapper {
      flex-direction: row;
      align-items: center;
    }

    &__status {
      padding-right: 28px;
      margin-bottom: 0;

      &::after {
        content: '';
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -2px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $blue-grey-70;
      }
    }

    &__request-date {
      font-size: 14px;
    }

    &__propose-wrapper {
      margin-bottom: 32px;
    }

    &__propose-title {
      font-size: 24px;
    }

    &__last-stream {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }

    &__stream-preview-title {
      font-size: 24px;
      margin-bottom: 16px;
    }

    &__response-buttons-wrapper {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & button {
        width: calc(50% - 4px);
      }

      & button:first-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }
}

@media ($desktop) {
  .my-gyre-events-modal {

    &__games-list {
      margin: 0 !important;
    }

    &__info-list {
      flex-wrap: wrap;
      margin-bottom: 16px;
    }

    &__info-item {
      width: 33%;
    }
  }
}