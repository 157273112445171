.promo {
  width: 100%;
  height: calc(100vh - 64px);
  margin-top: 68px;
  background-image: linear-gradient(19.44deg, $blue-grey 0%, $blue-grey 38.78%, rgba(42, 53, 110, 0) 100%),
    url('../../../images/promo-bg-mobile@1x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  position: relative;
  overflow: hidden;

  @media ($retina) {
    background-image: linear-gradient(
        19.44deg,
        $blue-grey 0%,
        $blue-grey 38.78%,
        rgba(42, 53, 110, 0) 100%
      ),
      url('../../../images/promo-bg-mobile@2x.jpg');
  }

  &__wrapper {
    height: 100%;
    @include container;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: 48px;

    @media (min-width: 375px) {
      padding-bottom: 120px;
    }
  }

  &__lead {
    display: block;
    @include bold;
    font-size: 32px;
    line-height: 38px;
    color: $white;
    margin-bottom: 16px;

    & span {
      color: $secondary;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-20;
    margin: 0;
    margin-bottom: 24px;
  }

  &__dec {
    position: absolute;

    &--border-red {
      left: 10px;
      top: 0px;
      width: 82px;
      height: 214px;
      transform: rotate(75deg);
      background: url('../../../images/border-rectangle.svg') no-repeat;
      background-size: contain;
    }

    &--thin-bg-red {
      width: 11px;
      height: 214px;
      top: -80px;
      right: 0;
      background: linear-gradient(180deg, $secondary 0%, rgba(251, 98, 105, 0) 100%);
      opacity: 0.5;
      border-radius: 79px;
      transform: rotate(75deg);
    }

    &--thin-bg-white {
      width: 11px;
      height: 141px;
      right: 70px;
      bottom: 15px;
      background: linear-gradient(180deg, #f4f5f8 0%, rgba(244, 245, 248, 0) 100%);
      opacity: 0.5;
      border-radius: 79.0246px;
      transform: rotate(75deg);
    }

    &--bold-bg-red {
      width: 56px;
      height: 214px;
      bottom: -98px;
      right: 100px;
      background: linear-gradient(180deg, $secondary 0%, rgba(251, 98, 105, 0) 100%);
      opacity: 0.3;
      border-radius: 79.0246px;
      transform: matrix(-0.26, -0.97, -0.97, 0.26, 0, 0);
    }
  }
}

@media ($tablet) {
  .promo {
    &__wrapper {
      padding-bottom: 160px;
    }
  }
}

@media ($desktop) {
  .promo {
    height: auto;
    padding-top: 233px;
    padding-bottom: 110px;
    margin-top: -122px;
    background: linear-gradient(75.96deg, $blue-grey 0%, $blue-grey 27.08%, rgba(42, 53, 110, 0) 100%),
      url('../../../images/promo-bg-desktop@1x.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;

    @media ($retina) {
      background-image: linear-gradient(
          75.96deg,
          $blue-grey 0%,
          $blue-grey 27.08%,
          rgba(42, 53, 110, 0) 100%
        ),
        url('../../../images/promo-bg-desktop@2x.jpg');
    }

    &__wrapper {
      justify-content: center;
      padding-bottom: 0;
    }

    &__lead {
      width: 735px;
      font-size: 56px;
      line-height: 56px;
    }

    &__description {
      width: 735px;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 48px;
    }

    &__dec {
      &--border-red {
        display: none;
      }

      &--thin-bg-red {
        width: 30px;
        height: 581px;
        top: -295px;
        right: 135px;
      }

      &--thin-bg-white {
        display: none;
      }

      &--bold-bg-red {
        display: none;
      }
    }
  }
}

@media ($desktop-hd) {
  .promo {
    background: linear-gradient(90deg, $blue-grey 0%, $blue-grey 27.08%, rgba(42, 53, 110, 0) 100%),
      url('../../../images/promo-bg-desktop-hd@1x.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 203px;
    padding-bottom: 260px;
    background-position: right top;

    @media ($retina) {
      background-image: linear-gradient(
          90deg,
          $blue-grey 0%,
          $blue-grey 27.08%,
          rgba(42, 53, 110, 0) 100%
        ),
        url('../../../images/promo-bg-desktop-hd@2x.jpg');
    }

    &__dec {
      &--border-red {
        display: block;
        width: 101px;
        height: 581px;
        background-image: url('../../../images/border-rectangle-desktop.svg');
        left: auto;
        right: 55px;
        top: 144px;
      }

      &--thin-bg-red {
        top: -258px;
        right: auto;
        left: 50%;
      }

      &--thin-bg-white {
        display: block;
        width: 30px;
        height: 384px;
        background: linear-gradient(180deg, #f4f5f8 0%, rgba(244, 245, 248, 0) 100%);
        border-radius: 215px;
        right: auto;
        left: 620px;
        bottom: -160px;
      }

      &--bold-bg-red {
        display: block;
        width: 152px;
        height: 581px;
        bottom: -300px;
        right: 450px;

        background: linear-gradient(180deg, $secondary 0%, rgba(251, 98, 105, 0) 100%);
        opacity: 0.3;
        border-radius: 215px;
        transform: matrix(-0.26, -0.97, -0.97, 0.26, 0, 0);
      }
    }
  }
}
