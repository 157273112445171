.unsubscribe {
  margin: 120px 0;
  &__container {
    @include container;
  }

  &__main-title {
    font-size: 32px;
  }

  &__title {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 18px;
  }

  &__status {
    font-size: 24px;
  }

  &__paragaph {
    line-height: 130%;
  }

  &__link {
    color: $blue-grey;
  }

  &__list {
    list-style: inside;
  }

  &__item {
    margin-bottom: 10px;
  }

  & + .footer {
    margin-bottom: 48px;
  }
}
