.sign-up-btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include bold;
  color: $blue-grey-70;
  font-size: 14px;
  padding: 16px 20px;
  background-color: $grey-15;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 300ms;
  text-decoration: none;
  border: none;

  &:hover {
    background-color: $grey-20;
  }

  &:active {
    background-color: $blue-grey;
    color: $white;

    & path,
    & rect {
      fill: $white;
    }
  }

  & svg {
    margin-right: 8px;
  }

  @media ($desktop) {
    font-size: 16px;
    padding: 22px 24px;
    background-position: 24px center;
  }
}
