.pagination {
  display: flex;
  align-items: center;
  border: 1px solid $blue-grey-20;
  border-radius: 16px;
  padding: 16px;
  gap: 15px;

  &__button {
    @include bold;
    width: 120px;
    padding: 15px 0;
    background-color: $grey-15;
    border-radius: 12px;
    font-size: 14px;
    color: $blue-grey-70;
    transition-duration: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
    }

    &:disabled {
      color: $blue-grey-40;
      cursor: default;

      &:hover {
        background-color: $grey-15;
      }
    }
  }
}

