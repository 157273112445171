.steps {
  margin-bottom: 56px;
  padding-top: 56px;
  // position: relative;

  &__wrapper {
    @include container;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    align-self: flex-start;
  }

  &__step-list {
    list-style: none;
    padding-left: 64px;
    position: relative;

    & a {
      text-decoration: none;
    }

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 80%;
      top: 63px;
      left: 18px;
      border-left: 1px dashed $blue-grey-30;
    }
  }

  &__step-item {
    padding: 16px 16px 24px;
    border-radius: 16px;
    background-color: $white;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      background-color: $grey;
      padding: 10px 0;
      top: 50px;
      left: -57px;
      @include bold;
      font-size: 40px;
      line-height: 48px;
      color: $secondary;
    }

    &--1 {
      &::before {
        content: '1';
      }
    }

    &--2 {
      &::before {
        content: '2';
      }
    }

    &--3 {
      &::before {
        content: '3';
      }
    }

    &--last {
      background-color: $primary-50;
      cursor: pointer;
      text-decoration: none;

      &::before {
        content: '4';
        color: $primary-50;
      }

      & .steps__step-description {
        color: $white;
      }
    }

    &:hover .steps__step-image-open {
      opacity: 1;
    }
  }

  &__step-image-wrapper {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: 2px solid $blue-grey-40;
    overflow: hidden;
    margin-bottom: 24px;
    box-sizing: content-box;
    position: relative;
  }

  &__step-image-open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($blue-grey, 0.8);
    transition-duration: 300ms;
    opacity: 0;
  }

  &__step-image {
    width: 100%;
    height: auto;
  }

  &__step-description {
    @include bold;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  &__step-item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 136px;
    background-color: $blue-grey;
    border-radius: 12px;
    margin-bottom: 24px;
  }
}

@media ($desktop) {
  .steps {
    margin-bottom: 77px;
    padding-top: 104px;
    &__step-list {
      width: 100%;
      padding-left: 216px;

      &::before {
        left: 170px;
      }
    }

    &__step-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 16px;
      border-radius: 24px;
      transition-duration: 400ms;

      &:before {
        top: 34px;
        left: -64px;
        font-size: 56px;
        line-height: 67px;
      }

      &:hover {
        box-shadow: 0px 4px 32px rgba(42, 53, 110, 0.12);
      }

      &--last {
        width: 663px;

        &:hover {
          width: 100%;

          & .steps__step-item-btn {
            background-color: $blue-grey-20;
          }
          & .steps__step-item-btn path,
          & .steps__step-item-btn rect {
            fill: $blue-grey;
          }
        }
      }
    }

    &__step-image-wrapper {
      width: 213px;
      height: 117px;
      margin-bottom: 0;
      margin-right: 48px;
      border-radius: 16px;
    }

    &__step-description {
      font-size: 32px;
      line-height: 38px;
      width: 600px;
    }

    &__step-item-btn {
      min-width: 213px;
      max-width: 213px;
      height: 117px;
      min-height: auto;
      margin-bottom: 0;
      margin-right: 48px;
      transition-duration: 300ms;
      color: $white;
    }
  }
}

@media ($desktop-hd) {
  .steps {
    &__wrapper {
      padding-left: 192px;
      padding-right: 192px;
    }
  }
}
