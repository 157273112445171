@media ($tablet) {
  .chat-page {
    display: flex;
    @include container;
    height: 100vh;
    padding-top: 112px;
    padding-bottom: 88px;
    gap: 15px;
  }
}

@media ($desktop) {
  .chat-page {
    height: calc(100vh - 72px);
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 32px;
  }
}
