.collaboration-request {
  margin-top: 90px;
  margin-bottom: 130px;

  &__wrapper {
    @include container;
  }

  &__title {
    font-size: 32px;
    line-height: 38px;
    margin: 0;
    margin-bottom: 8px;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: $blue-grey-70;
    margin-bottom: 40px;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    margin-bottom: 16px;
    color: $blue-grey-60;
  }

  &__streamer-card {
    background-color: $white;
    width: 100%;
    border-radius: 16px;
    padding: 12px 14px;
    margin-bottom: 40px;

    @media (min-width: 375px) {
      padding: 16px 24px;
    }
  }

  &__nickname {
    font-size: 18px;
  }

  &__info-item {
    width: 33%;
    margin-bottom: 16px;
  }

  &__about-gyre {
    margin-bottom: 40px;

    &--desktop {
      display: none;
    }
  }

  &__section-title {
    padding-bottom: 12px;
    border-bottom: 1px solid $blue-grey-30;
  }

  &__streamer-name {
    @include bold;
    display: inline-block;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    position: relative;
    color: $blue-grey;
    padding-left: 40px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      background-image: url('../../../images/quotes.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }

  &__propose-wrapper {
    margin-bottom: 40px;
  }

  &__propose-title {
    font-size: 20px;
    line-height: 24px;
    color: $blue-grey;
    margin: 0;
    margin-bottom: 12px;
  }

  &__last-stream-container {
    padding: 15px;
    border-radius: 12px;
    border: 1px solid $blue-grey-30;
    margin-bottom: 40px;
  }

  &__preview-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 8px;
    width: 100%;
    height: calc(70vw - 58px);
    overflow: hidden;
  }

  &__stream-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__last-stream-title {
    font-size: 20px;
    line-height: 24px;
    color: $blue-grey;
    margin: 0;
    margin-bottom: 24px;
  }

  &__twitch-link {
    width: 100%;
    font-size: 14px;

    & svg {
      margin-right: 8px;
    }
  }

  &__buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 24px;
    border-top: 1px solid $blue-grey-30;
  }

  &__accept-button {
    border: none;
    margin-right: 16px;
  }
}

@media ($desktop) {
  .collaboration-request {
    margin-top: 128px;
    margin-bottom: 154px;

    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__container {
      width: 720px;
    }

    &__title {
      font-size: 56px;
      line-height: 67px;
      margin-bottom: 16px;
    }

    &__description {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
      white-space: pre-wrap;

      &--block-description {
        margin-bottom: 48px;
      }
    }

    &__subtitle {
      font-size: 32px;
      line-height: 38px;
    }

    &__streamer-card {
      padding: 24px 32px 8px 32px;
      margin-bottom: 48px;
    }

    &__info-item {
      width: 25%;
    }

    &__about-gyre {
      &--mobile {
        display: none;
      }

      &--desktop {
        margin-top: 248px;
        width: 272px;
        display: block;
      }
    }

    &__section-title {
      padding-bottom: 12px;
      margin-bottom: 32px;
    }

    &__streamer-name {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 24px;
    }

    &__propose-wrapper {
      margin-bottom: 48px;
    }

    &__propose-title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }

    &__last-stream-container {
      display: flex;
      margin-bottom: 48px;
    }

    &__preview-wrapper {
      width: 157px;
      height: 88px;
      margin-right: 24px;
      margin-bottom: 0;
    }

    &__last-stream-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &__accept-button {
      padding: 16px 113px;
    }

    &__reject-button {
      padding: 16px 122px;
    }
  }
}

@media ($desktop-hd) {
  .collaboration-request {
    &__container {
      width: 928px;
    }
  }
}
