.selector-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #151A37;
    opacity: 0.5;
    z-index: 1000;
}

.selector-is-opened {
    z-index: 1001;
    position: relative;
}