.top-events {
  padding: 16px;
  border: 1px solid $blue-grey-20;
  border-radius: 12px;
  margin-bottom: 24px;

  &__header {
    margin-bottom: 24px;
  }

  &__title-wrapper {
    position: relative;
    padding-left: 44px;
    margin-bottom: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      background-image: url('../../../images/logo-min.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &__title {
    @include bold;
    margin: 0 0 8px;
    font-size: 24px;
  }

  &__description {
    margin: 0;
    font-size: 14px;
    line-height: 140%;
    color: $blue-grey-70;
  }

  &__all-events-link {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-50;
    border-radius: 12px;
    padding: 12px 20px;
    text-decoration: none;
    color: $white;
    @include bold;
    transition-duration: 300ms;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: $primary-40;
    }

    &:active {
      background-color: $primary-60;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $grey-15;
    border-radius: 12px;
    padding: 24px;
    cursor: pointer;
    transition-duration: 400ms;

    &:hover {
      box-shadow: 0px 4px 32px rgba(42, 53, 110, 0.12);
    }
  }

  &__about {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__description-wrapper {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid $blue-grey-30;
  }

  &__event-title {
    font-size: 16px;
    line-height: 120%;
    @include bold;
    margin: 0 0 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 20px;
  }

  &__event-description {
    margin: 0;
    font-size: 14px;
    line-height: 140%;
    color: $blue-grey-70;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 40px;
  }
}

@media ($tablet) {

  .top-events {

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title-wrapper {
      margin-bottom: 0;
    }
  }
}

@media ($desktop) {

  .top-events {
    padding: 20px 32px 20px 32px;
    margin-bottom: 20px;

    &__title-wrapper {
      padding-left: 64px;

      &::before {
        width: 40px;
        height: 40px;
      }
    }

    &__title {
      font-size: 26px;
    }

    &__description {
      font-size: 16px;
    }

    &__list {
      flex-direction: row;
    }

    &__item {
      width: 362px;
    }

    &__games-list {
      margin-right: 0;
    }
  }
}

@media ($desktop-hd) {

  .top-events {

    &__item {
      width: 485px;
    }
  }
}
