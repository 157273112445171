.suggest-collab-spoiler {
  background-color: $white;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 8px;
  position: relative;
  transition-duration: 300ms;

  &.is-open-overflow {
    .suggest-collab-spoiler__contentOuter {
      overflow: visible !important;
    }
  }

  &.is-closed:hover {
    box-shadow: 0px 4px 32px rgba(42, 53, 110, 0.12);
  }

  &:last-child::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -24px;
    width: 5px;
    height: calc(100% - 40px);
    background-color: $grey;
  }

  &__trigger {
    @include bold;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    position: relative;
    padding: 12px 0;
    cursor: pointer;
    user-select: none;

    &.error::before {
      background-image: url('../../../images/progress-error.svg');
    }

    &.is-open.error::before {
      background-image: url('../../../images/progress-error-active.svg');
    }

    &.success::before {
      background-image: url('../../../images/progress-finished.svg');
    }

    &.is-open.success::before {
      background-image: url('../../../images/progress-finished-active.svg');
    }

    &::before {
      content: '';
      position: absolute;
      left: -48px;
      top: 50%;
      margin-top: -27px;
      width: 24px;
      height: 54px;
      background: url('../../../images/progress-unfinished.svg') no-repeat;
      background-size: contain;
      background-position: center;
      background-color: $grey;
      transition-duration: 300ms;
    }

    &.is-open::before {
      background-image: url('../../../images/progress-active.svg');
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;

      width: 20px;
      height: 20px;
      margin-top: -2px;

      -webkit-mask-image: url('../../../images/arrow-bottom-icon.svg');
      mask-image: url('../../../images/arrow-bottom-icon.svg');
      background-color: $blue-grey-70;
      background-position: center;
      background-size: 100%;

      transition-duration: 300ms;
    }

    &.is-open {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__contentInner {
    margin-top: 15px;
    border-top: 1px solid $blue-grey-20;
    padding-top: 24px;
  }
}

@media ($tablet) {
  .suggest-collab-spoiler {
    margin-bottom: 12px;
    padding: 16px 32px;

    &:last-child::before {
      left: -48px;
    }

    &__trigger {
      font-size: 20px;
      line-height: 24px;

      &::before {
        width: 40px;
        height: 72px;
        left: -96px;
        margin-top: -36px;
      }
    }
  }
}
