.main-nav {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  background-color: $white;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    @include container;
  }

  &__logo-link {
    display: flex;
  }

  &__logo {
    width: 90px;
  }

  &__userbar {
    display: flex;
    align-items: center;
  }

  &__messages {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
    background: url('../../images/chat-icon.svg') no-repeat;
    background-position: center;
    background-size: 100%;
    border: none;
  }

  &__userbar-action {
    position: relative;
  }

  &__userbar-arrow {
    display: inline-block;
    width: 20px;
    height: 20px;
    transition-duration: 300ms;
  }

  &__userbar-container {
    display: flex;
    align-items: center;
    padding: 8px 6px 8px 8px;
    border-radius: 8px;
    transition-duration: 300ms;
    cursor: pointer;

    &:hover {
      background-color: $blue-grey-25;
      color: $blue-grey;
    }

    &--opened {
      background-color: $blue-grey-25;
      color: $blue-grey;

      & .landing-header__userbar-arrow {
        transform: rotate(180deg);
      }

      & .landing-header__userbar-arrow path {
        fill: $secondary-60;
      }
    }
  }

  &__user-menu {
    width: 180px;
    background-color: $blue-grey;
    border-radius: 16px;
    position: absolute;
    top: 70px;
    right: 0;
    overflow: hidden;
  }

  &__user-menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__user-menu-link {
    display: block;
    padding: 13px 16px 13px 51px;
    @include bold;
    color: $white;
    text-decoration: none;
    position: relative;

    &:hover {
      background-color: $blue-grey-70;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 18px;
      width: 20px;
      height: 20px;
    }

    &--logout {
      &::before {
        background-position: center;
        background-size: 100%;
        background: url('../../images/logout-icon.svg') no-repeat;
      }
    }
  }

  &__user-name {
    margin-right: 8px;
    font-size: 12px;
    font-weight: 500;
  }

  &__avatar-wrapper {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    border: 2px solid $blue-grey-20;
    border-radius: 50%;
    overflow: hidden;
  }

  &__avatar {
    width: 100%;
  }
}

@media ($desktop) {
  .main-nav {
    &__wrapper {
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
    }

    &__logo-link {
      position: absolute;
      left: 50%;
      margin-left: -74px;
    }

    &__logo {
      width: 148px;
    }

    &__userbar-container {
      &:hover {
        background-color: $blue-grey-25;
        opacity: 0.9;
      }

      &--closed:hover {
        & .landing-header__userbar-arrow path {
          fill: $blue-grey;
        }
      }
    }

    &__userbar {
      position: absolute;
      z-index: 1;
      right: 0;
    }

    &__user-name {
      margin-right: 14px;

      font-size: 14px;
    }

    &__avatar-wrapper {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
