.bottom-banners {
  padding-top: 20px;

  &__wrapper {
    display: inline-flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
  }

  &__collab-hub {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid $blue-grey;
    border-radius: 24px;

    .bottom-banners__icon svg {
      color: $blue-grey-50;
    }
  }

  &__discord {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid $discord-color;
    border-radius: 24px;

    .bottom-banners__icon svg {
      color: $discord-color;
    }
  }

  &__text {
    padding-right: 40px;
    margin-bottom: 20px;

    .big-text {
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
    }

    .small-text {
      font-size: 14px;
      line-height: 140%;
    }
  }

  &__icon {
    position: absolute;
    top: 5%;
    right: 5%;

    svg {
      width: 40px;
      height: auto;
    }
  }

  &__link {
    a {
      width: 180px;
      height: 48px;
      padding: 0;

      .small-arrow {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__discord-btn {
    background-color: $discord-color;

    &:hover{
      background-color: $discord-hover-color;
    }
  }

  &__collab-hub-btn {
    background-color: $secondary;

    &:hover{
      background-color: $secondary-hover;
    }
  }
}

@media ($tablet) {
  .bottom-banners {
    &__wrapper {
      flex-direction: initial;
    }
  }
}

@media ($desktop) {
  .bottom-banners {
    &__wrapper {
      flex-direction: initial;
    }
  }
}
