.chat-list {
  margin-top: 90px;
  margin-bottom: 90px;

  &__container {
    padding-right: 18px;
    padding-left: 18px;
  }

  &__title {
    margin: 0 0 16px;
    font-size: 24px;
    line-height: 29px;
  }

  &__card {
    height: 72px;
    margin-bottom: 8px;
    background-color: $white;
    border-radius: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &--current {
      background-color: $blue-grey;

      & .chat-list__streamer-status {
        border-color: $blue-grey;
      }

      & .chat-list__streamer-nickname {
        color: $grey-10;
      }

      & .chat-list__last-message,
      .chat-list__send-time {
        &::before {
          background-color: $blue-grey-30;
        }

        color: $blue-grey-30;
      }
    }
  }

  &__link-to-chat {
    display: flex;
    align-items: center;
    padding: 12px 27px 12px 8px;
    text-decoration: none;
  }

  &__avatar-and-status {
    position: relative;
    margin-right: 12px;
  }

  &__avatar-wrapper {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:before {
      content: ' ';
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-image: url('../../../images/default-streamer-avatar.png');
      background-size: 100%;
    }
  }

  &__streamer-status-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 12px;
    height: 12px;
  }

  &__streamer-status {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: $blue-grey-50;
    border: 2px solid $white;
    border-radius: 50%;

    &--online {
      background-color: $green-50;
    }
  }

  &__info-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__streamer-nickname {
    @include bold;
    margin: 0 0 4px;
    font-size: 16px;
    line-height: 20px;
    color: $blue-grey-70;

    &--unread {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $secondary;
      }
    }
  }

  &__last-message-wrapper {
    display: flex;
  }

  &__last-message {
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-60;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__send-time {
    position: relative;
    display: inline-block;
    align-self: flex-end;
    margin-left: 4px;
    padding-left: 8px;
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-70;
    white-space: nowrap;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: 2px;
      margin-top: -1px;
      background-color: $blue-grey-70;
      border-radius: 50%;
    }
  }

  &__info-wrapper--empty {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      background: url('../../../images/angle-icon.svg') no-repeat center;
    }
  }
}

@media ($tablet) {
  .chat-list {
    min-width: 272px;
    max-width: 272px;
    height: 100%;
    margin: 0;
    overflow: hidden;
    border: 1px solid $blue-grey-20;
    border-radius: 24px;

    &__container {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
    }

    &__title {
      display: block;
      margin-bottom: 16px;
      padding: 32px 24px 0;
      font-size: 32px;
      line-height: 38px;
    }

    &__list {
      height: 100%;
      padding: 16px 8px;
      overflow-y: auto;
    }
  }
}

@media ($desktop-hd) {
  .chat-list {
    min-width: 352px;
    max-width: 352px;
  }
}
