.sorting {
  border: 1px solid $blue-grey-20;
  padding: 16px;
  border-radius: 16px;
  margin-top: 42px;
  margin-bottom: 24px;

  &-selector {
    &__control {
      width: 100% !important;
    }
  }

  &__input-wrapper {
    position: relative;
    margin-top: 16px;
  }

  &__input-label {
    font-size: 14px;
    color: $blue-grey-60;
    font-weight: 500;
    background-color: $grey;
    padding: 0 4px;
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 2;
    margin-left: 12px;
    transition-duration: 300ms;
  }

  &__input {
    @include bold;
    border-radius: 12px;
    background-color: $grey;
    width: 100%;
    height: 48px;
    outline: none;
    border: 1px solid $blue-grey-40;
    padding: 16px 14px;
    font-size: 14px;
    line-height: 17px;
    color: $blue-grey;

    &:hover {
      border-width: 2px;
      border-color: $blue-grey-40 !important;
    }

    &:focus {
      border-width: 2px;
      border-color: $primary-50 !important;
    }
  }

  &__input-wrapper input.filled + label,
  &__input-wrapper input:focus + label,
  &__input-wrapper input:hover + label {
    top: -5px;
    font-size: 12px;
    font-weight: 400;
    color: $blue-grey-50;
  }
}

@media ($tablet) {
  .sorting {
    &__wrapper {
      display: flex;
      width: 100%;
    }

    &__input-wrapper {
      width: 203px;
      margin-top: 0;
      margin-left: 10px;
    }
  }
}

@media ($desktop) {
  .sorting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 32px;

    &__input-wrapper {
      width: 305px;
    }
  }
}
