.chat-window {
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 64px;
    padding-bottom: 48px;

    &--empty {
      align-items: center;
      justify-content: center;
    }
  }

  &__no-active-chats {
    @include bold;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    color: $blue-grey-60;
    max-width: 345px;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 13px 18px;
    background-color: $white;
  }

  &__go-back-btn {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background-image: url('../../../images/arrow-left-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  &__avatar-and-status {
    position: relative;
    margin-right: 12px;
  }

  &__avatar-wrapper {
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:before {
      content: ' ';
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-image: url('../../../images/default-streamer-avatar.png');
      background-size: 100%;
    }
  }

  &__streamer-status-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 12px;
    height: 12px;
  }

  &__streamer-status {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: $blue-grey-50;
    border: 2px solid $white;
    border-radius: 50%;

    &--online {
      background-color: $green-50;
    }
  }

  &__receiver-nickname {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
  }

  &__receiver-nickname-wrapper {
    flex-grow: 1;
  }

  &__receiver-status {
    font-size: 12px;
    line-height: 17px;
    color: $blue-grey-70;
  }

  &__about-streamer-button {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('../../../images/info-circle-blue.svg') no-repeat center;
    background-size: contain;
    border: none;
  }

  &__messages-container {
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    overflow: auto;
  }

  &__messages-wrapper {
    width: 100%;
    padding-top: 20px;
    padding-right: 18px;
    padding-left: 18px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__came-from {
    font-size: 14px;
    border: 1px solid $blue-grey-20;
    border-radius: 16px;
    padding: 18px 18px 18px 56px;
    margin-bottom: 16px;
    position: relative;

    &::before {
      content: '!';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 16px;
      left: 18px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: $blue-grey;
      background-color: rgba($blue-grey, 0.3);
    }

    &-link {
      cursor: pointer;
      color: $primary-50;
      border-bottom: 1px solid $primary-50;
      transition-duration: 200ms;

      &:hover {
        color: $primary-60;
        border-color: $primary-60;
      }
    }
  }

  &__message-wrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 20px;
    animation-name: fade;
    animation-duration: 100ms;
    white-space: pre-wrap;

    &--received {
      justify-content: flex-start;
    }

    &--sent {
      justify-content: flex-end;
    }
  }

  &__message-block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    &--received {
      align-items: flex-start;

    }

    &--sent {
      align-items: flex-end;
      margin-right: 20px;
    }
  }

  &__sender-avatar-wrapper {
    display: inline-block;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    margin-right: 20px;
    margin-bottom: 21px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__sender-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:before {
      content: ' ';
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-image: url('../../../images/default-streamer-avatar.png');
      background-size: 100%;
    }
  }

  &__message-block {
    position: relative;
    margin-bottom: 4px;
    padding: 16px 20px;
    background-color: $white;
    border-radius: 16px;

    &--received {
      max-width: calc(100% - 60px);
      border-bottom-left-radius: 0px;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -20px;
        width: 20px;
        height: 20px;
        background-image: url('../../../images/corner-incoming-message.svg');
        background-repeat: no-repeat;
        background-size: 20px 20px;
      }
    }

    &--sent {
      max-width: calc(100% - 60px);
      background-color: $blue-grey;
      border-top-right-radius: 0;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        width: 20px;
        height: 20px;
        background-image: url('../../../images/corner-sent-message.svg');
        background-repeat: no-repeat;
        background-size: 20px 20px;
      }
    }
  }

  &__message-text {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;

    &--sent {
      color: $white;
    }
  }

  &__message-time {
    font-size: 12px;
    line-height: 17px;
    color: $blue-grey-50;
  }

  &__message-input-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    background-color: $grey-10;
  }

  &__send-message-input {
    flex-grow: 1;
    min-height: 48px;
    max-height: 100px;
    margin-right: 16px;
    padding: 16px;
    font-size: 14px;
    color: $blue-grey-70;
    background-color: $white;
    border: 1px solid $blue-grey-40;
    border-radius: 12px;
    resize: none;

    &::placeholder {
      color: $blue-grey-50;
    }
  }

  &__send-message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    & svg path {
      transition-duration: 300ms;
    }

    &:hover {
      & path {
        fill: $primary-40;
      }
    }

    &:active {
      & path {
        fill: $primary-60;
      }
    }
  }
}

@media ($tablet) {

  .chat-window {
    flex-grow: 1;
    width: auto;
    height: 100%;
    border: 1px solid $blue-grey-20;
    border-radius: 24px;

    &__container {
      padding: 0;
    }

    &__header {
      position: static;
      z-index: 0;
      width: calc(100% - 48px);
      height: auto;
      margin: 0 24px;
      padding: 16px 0;
      background: none;
      border-bottom: 1px solid $blue-grey-20;
    }

    &__avatar-wrapper {
      min-width: 48px;
      max-width: 48px;
      height: 48px;
    }

    &__receiver-nickname {
      font-size: 20px;
      line-height: 24px;
    }

    &__recevier-status {
      font-size: 14px;
      line-height: 20px;
    }

    &__messages-wrapper {
      padding-right: 24px;
      padding-left: 24px;
    }

    &__message-input-wrapper {
      width: calc(100% - 48px);
      margin: 0 24px;
      padding: 16px 0;
      background: none;
      border-top: 1px solid $blue-grey-20;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}