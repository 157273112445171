.navigation-bar {
  display: flex;
  position: fixed;
  z-index: 50;
  left: 0;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 48px;
  background-color: $blue-grey;

  &__item {
    width: 25%;
    position: relative;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $blue-grey-40;
    font-size: 9px;
    line-height: 12px;
    text-decoration: none;
    padding: 6px 1px;

    & span {
      width: calc(99%);
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--current {
      color: $secondary;
    }
  }
}

@media ($desktop) {
  .navigation-bar {
    position: static;
    z-index: 0;
    background: none;
    align-items: center;
    width: auto;

    &__item {
      width: auto;
    }

    &__link {
      display: inline-block;
      @include bold;
      padding: 14px 15px;
      width: 100%;
      height: 100%;
      font-size: 12px;
      color: $blue-grey;
      line-height: 19px;
      border-radius: 12px;
      transition-duration: 300ms;

      & span {
        width: auto;
        overflow: visible;
      }

      @media ($desktop-hd) {
        font-size: 16px;
        padding: 14px 20px;
      }

      &:hover {
        color: $secondary;
      }

      &:active {
        color: $secondary-60;
      }

      & svg {
        display: none;
      }

      &--current {
        color: $white;
        background-color: $primary-60;
        cursor: default;

        &:hover {
          color: $white;
        }
      }
    }
  }
}
