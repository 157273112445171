.features {
  padding-top: 56px;
  margin-bottom: 56px;

  &__wrapper {
    @include container;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__container {
    width: calc(50% - 12px);
    display: flex;
    flex-direction: column;
  }

  &__item-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 1px solid $blue-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 102%;
    background-position: center;

    &--1 {
      background-image: url('../../../images/features-icon-1.svg');
    }

    &--2 {
      background-image: url('../../../images/features-icon-2.svg');
    }

    &--3 {
      background-image: url('../../../images/features-icon-3.svg');
    }

    &--4 {
      background-image: url('../../../images/features-icon-4.svg');
    }

    &--5 {
      color: $discord-color;
      border: none;

      svg {
        width: 74px;
        height: 74px;
      }
    }
  }

  &__item {
    padding-top: 112px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    position: relative;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &--discord {
      padding-top: 100px;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
    }
  }

  &__item-title {
    @include bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    margin-bottom: 8px;
  }

  &__item-description {
    font-size: 14px;
    line-height: 20px;
    color: $blue-grey-70;
    margin: 0;
  }

  &__collaborate-btn {
    width: 100%;
  }

  &__discord-btn {
    background-color: $discord-color;
    width: 96px;
    height: 48px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 20px;

    .small-arrow {
      width: 20px;
      height: 20px;
    }
  }
}

@media ($tablet) {
  .features {
    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__list {
      flex-direction: column;
      margin-bottom: 40px;
    }

    &__container {
      width: 100%;
    }

    &__item {
      width: 100%;
      height: 169px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 0 0 120px;
      border-top: 1px solid $blue-grey-40;
      margin-bottom: 0;
      margin-top: -1px;

      &--discord {
        margin-top: auto;
        border-bottom: 1px solid $blue-grey-40;
        margin-bottom: 48px;
      }

      &--discord {
        p {
          padding-right: 98px;
        }

        &:last-child {
          border-bottom-color: $discord-color;
        }
      }
    }

    &__item-icon {
      top: 50%;
      margin-top: -44px;
    }

    &__collaborate-btn {
      width: auto;
      align-self: flex-start;
    }

    &__discord-btn {
      top: auto;
    }
  }
}

@media ($desktop) {
  .features {
    padding-top: 104px;
    margin-bottom: 104px;

    &__list {
      margin-left: 152px;
      margin-bottom: 0;
      //margin-bottom: 40px;
    }

    &__item {

      &--discord {
        width: 1032px;
        margin-left: 152px;
        margin-bottom: 40px;
      }
    }

    &__item-title {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 12px;
    }

    &__item-description {
      font-size: 16px;
      line-height: 22px;
    }

    &__collaborate-btn {
      margin-left: 272px;
    }
  }
}

@media ($desktop-hd) {
  .features {
    &__wrapper {
      padding: 0 190px;
    }

    &__list {
      margin-left: 192px;
    }

    &__item {
      &--discord {
        width: 932px;
        margin-left: 192px;
      }
    }

    &__collaborate-btn {
      margin-left: 312px;
    }
  }
}
