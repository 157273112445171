.suggest-collab {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;

  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($blue-grey-70, 0.5);
  }

  &__modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
  }

  &__modal {
    width: calc(100% - 32px);
    background-color: $grey;
    border-radius: 16px;
    position: absolute;
    top: 100px;
    left: 16px;
    padding: 32px 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  &__title {
    @include bold;
    font-size: 32px;
    line-height: 38px;
    margin: 0;
  }

  &__close-button {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: $grey-15;
    border: none;
    cursor: pointer;
    transition-duration: 300ms;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      background-color: $grey-20;
    }
  }

  &__streamer-card {
    background-color: $white;
    width: 100%;
    border-radius: 16px;
    padding: 16px 24px;
    margin-bottom: 24px;
  }

  &__nickname {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: 527px) {
      font-size: 20px;
      width: auto;
    }
  }

  &__twitch-link {
    font-size: 12px;
    margin-top: -3px;
    margin-left: 55px;

    & svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    @media (min-width: 375px) {
      font-size: 14px;
      margin-left: 64px;

      & svg {
        width: 19px;
        height: 19px;
        margin-right: 8px;
      }
    }

    @media (min-width: 527px) {
      margin: 0;
    }
  }

  &__spoilers-container {
    padding-left: 32px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 11px;
      width: 5px;
      height: calc(100% - 40px);
      border-left: 1px dashed $blue-grey-30;
    }
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 14px;
  }

  &__input-counter {
    position: absolute;
    bottom: 10px;
    right: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  &__input-spinner {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__input-label {
    font-size: 14px;
    color: $blue-grey-60;
    font-weight: 500;
    background-color: $white;
    padding: 0 4px;
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 2;
    margin-left: 12px;
    transition-duration: 300ms;
  }

  &__input {
    @include bold;
    border-radius: 12px;
    width: 100%;
    height: 51px;
    outline: none;
    border: 1px solid $blue-grey-40;
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
    color: $blue-grey;

    &.error {
      border-color: $red-40;
    }

    &.success {
      border-color: $green-40;
    }

    &:hover {
      border-width: 2px;
      border-color: $blue-grey-40 !important;
    }

    &:focus {
      border-width: 2px;
      border-color: $primary-50 !important;
    }

    &::placeholder {
      color: $blue-grey-50;
      opacity: 0;
      transition-delay: 100ms;
      transition-duration: 200ms;
    }

    &:hover::placeholder {
      opacity: 1;
    }

    &--textarea {
      height: auto;
      min-height: 104px;
      resize: vertical;
      padding-bottom: 32px;
    }
  }

  &__input-wrapper input.filled + label,
  &__input-wrapper input:focus + label,
  &__input-wrapper input:hover + label,
  &__input-wrapper textarea.filled + label,
  &__input-wrapper textarea:focus + label,
  &__input-wrapper textarea:hover + label {
    top: -5px;
    font-size: 12px;
    font-weight: 400;
    color: $blue-grey-50;
  }

  &__error-message {
    display: block;
    font-size: 12px;
    line-height: 17px;
    color: $red-40;
    margin-bottom: 14px;
  }

  &__example {
    @include bold;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    padding-left: 28px;
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background: url('../../../images/info-circle.svg') no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }

  &__example-text {
    font-size: 12px;
    line-height: 17px;
    color: $blue-grey-70;
    margin: 0;
    padding-bottom: 32px;
    border-bottom: 1px solid $blue-grey-20;
    margin-bottom: 16px;
  }

  &__description {
    @include bold;
    font-size: 14px;
    line-height: 17px;
    color: $blue-grey;
    margin: 0;
    margin-bottom: 24px;
  }

  &__send-request-button {
    width: 100%;
    font-size: 14px;
    color: $white;
    @include bold;
    background-color: $primary-50;
    border: none;
    padding: 15px 20px;
    border-radius: 12px;
    margin-top: 24px;
    cursor: pointer;
    transition-duration: 300ms;

    &:hover {
      background-color: $primary-40;
    }

    &:active {
      background-color: $primary-60;
    }

    &--error {
      background-color: $secondary;

      &:hover {
        background-color: $secondary;
      }

      &:active {
        background-color: $secondary;
      }
    }
  }

  &__indent {
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    pointer-events: none;
  }
}

@media ($tablet) {
  .suggest-collab {
    &__modal {
      width: 736px;
      top: 100px;
      left: 50%;
      margin-left: -368px;
      padding: 40px;
    }

    &__streamer-card {
      border-radius: 24px;
      padding: 24px 32px;
      margin-bottom: 40px;
    }

    &__spoilers-container {
      padding-left: 64px;

      &::before {
        left: 19px;
      }
    }

    &__example {
      font-size: 16px;
      line-height: 19px;
      padding-left: 32px;

      &::before {
        width: 24px;
        height: 24px;
        margin-top: -12px;
      }
    }

    &__example-text {
      font-size: 14px;
      line-height: 20px;
    }

    &__input-counter {
      bottom: 12px;
    }
  }
}

@media ($desktop) {
  .suggest-collab {
    &__info-list {
      flex-wrap: wrap !important;
      margin-bottom: 16px;
    }

    &__info-item {
      width: 33%;
    }
  }
}
