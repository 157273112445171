.streamer-info {
  &__about {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid $blue-grey-20;
    gap: 10px 0;
  }

  &__avatar-wrapper,
  &__avatar-wrapper-small{
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid $blue-grey-20;
    overflow: hidden;
    margin-right: 12px;
    padding: 4px;

    &--verified {
      border-color: $primary-50;
    }

    &--system {
      border-color: $green-50;
    }
  }

  &__avatar-wrapper-small {
    width: 42px;
    height: 42px;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &:before {
      content: ' ';
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-image: url('../../images/default-streamer-avatar.png');
      background-size: 100%;
    }
  }

  &__nickname-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__nickname,
  &__nickname-small {
    display: inline-block;
    @include bold;
    color: $blue-grey;
    font-size: 20px;
    line-height: 24px;
  }

  &__nickname-small {
    font-size: 18px;
    line-height: 22px;
  }

  &__partner-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }

  &__break {
    width: 100%;
  }
  
  &__profile-info {
    margin-top: 8px;
  }

  &__copy-button {
    border-radius: 12px;
    background-color: $grey-15;
    border: none;
    cursor: pointer;
    transition-duration: 300ms;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    padding: 2px 4px 2px 4px;
    margin-left: 4px;

    &:hover {
      background-color: $grey-20;
    }

    &:active {
      background-color: $blue-grey;
    }
  }

  &__games-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    //margin-top: 18px;
    position: relative;

    &__hide-overflow {
      flex-wrap: nowrap;
    }
  }

  &__more-games-item {
    height: 30px;
  }

  &__show-more-games-btn {
    display: flex;
    height: 100%;
    align-items: center;
    background: $blue-grey-20;
    border-radius: 8px;
    font-weight: 500;
    color: $blue-grey;
    border: none;
    padding: 7px 13px;
    font-size: 12px;
    margin: 2px;
    cursor: pointer;

    .elipse {
      background: $blue-grey-60;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin: -1px 1px 0 1px;

      &:first-child {
        margin-left: 8px;
      }
    }

    &:hover + .streamer-info__more-games-list {
      display: flex;
    }
  }

  &__more-games-list {
    position: absolute;
    min-width: 270px;
    top: 38px;
    left: 0;
    display: none;
    flex-wrap: wrap;
    box-shadow: 0px 4px 12px rgba(7, 7, 73, 0.08);
    border-radius: 16px;
    background-color: $white;
    padding: 8px;
    z-index: 3;
  }

  &__games-item {
    @include bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    font-size: 12px;
    line-height: 14px;
    color: $blue-grey;
    border: 1px solid $blue-grey-70;
    border-radius: 8px;
    margin: 2px;
    white-space: nowrap;
  }

  &__info-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  &__info-item {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 4px;
  }

  &__no-categories {
    margin-top: 5px;
    font-size: 16px;
    @include bold;
  }

  &__info-title {
    font-size: 12px;
    line-height: 17px;
    color: $blue-grey-60;
    margin-bottom: 4px;
  }

  &__info-value {
    @include bold;
    color: $blue-grey;
    font-size: 13px;
    line-height: 19px;

    @media (min-width: 375px) {
      font-size: 16px;
    }
  }

  &__suggest-button {
    width: 100%;
  }
}

@media ($tablet) {
  .streamer-info {
    &__info-list {
      flex-wrap: wrap;
    }

    &__info-item {
      width: 33%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 4px;
      }
    }
  }
}

@media ($desktop) {
  .streamer-info {
    &__about {
      justify-content: space-between;
      align-items: flex-start;
    }

    &__avatar-wrapper {
      width: 56px;
      height: 56px;
      margin-right: 16px;
    }

    &__nickname-wrapper {
      margin-bottom: 8px;
    }

    &__nickname {
      font-size: 24px;
      line-height: 29px;
      max-width: 215px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__nickname-small {
      font-size: 17px;
      line-height: 20px;
    }

    &__games-list {
      margin-top: -42px;
      margin-left: 72px;
      margin-right: 280px;
    }

    &__twitch-link {
      margin-right: 8px;
    }

    &__suggest-button {
      width: auto;
    }

    &__info-list {
      flex-wrap: nowrap;
      margin-bottom: 0;
    }

    &__info-item {
      width: 16.66%;
      margin-bottom: 0;
    }
  }
}
